<template>
  <MainViewLayout
    v-if="renderComponent"
    data-test-id="booking-summary-layout"
  >
    <template slot="main-content">
      <IdentityCardModalComponent
        v-if="isUserIdentityCardModalOpen"
        :user-uuid="driverOrUserUuid"
        :is-booking-on-behalf="reservation.isBookingBehalfEmployee"
        @on:success="onClickCreateReservation"
        @on:error="isIdentityCardFeedbackModalOpen = true"
        @on:close-modal="isUserIdentityCardModalOpen = false"
      />
      <FeedbackModalComponent
        :is-open="isIdentityCardFeedbackModalOpen"
        :image="genericErrorImage"
        :title="$t('notifications.whooops')"
        :primary-call-to-action="() => isIdentityCardFeedbackModalOpen = false"
        :primary-call-to-action-text="$t('buttons.try_again')"
      />
      <ui-modal
        :header="$t('notifications.company_status_unpaid')"
        :open="showUnpaidCompanyModal"
        closable
        @close="showUnpaidCompanyModal = false"
      >
        <div slot="body">
          <p>{{ $t('notifications.company_unpaid_message') }}</p>
        </div>
        <div slot="footer">
          <div class="d-flex justify-content-end">
            <ui-button
              v-bind="fetchButtonSpecs()"
              @clickbutton="showUnpaidCompanyModal = false"
            >
              {{ $t('buttons.ok') }}
            </ui-button>
          </div>
        </div>
      </ui-modal>
      <div class="MapViewLayout LayoutPage">
        <div class="Booking">
          <div class="Booking__vehicleColumn">
            <div class="d-flex my-4 mx-6">
              <ui-icon
                :icon="ICONS.arrowBack"
                :size="ICONS_SIZES.small"
                :color="GRAYSCALE.ink"
                hover
                class="mx-2 cursor-pointer"
                alt="Go back"
                @click="goBack"
              />
              <h2>{{ $t("views.booking.title") }}</h2>
            </div>
            <div class="Booking__content pb-6 px-6">
              <div class="phLocation">
                <LocationHeaderMyBookingComponent
                  :locations="headerAddress"
                  :dates="headerDates"
                />
                <AlertComponent
                  v-if="isOutsideWorkingHours"
                  :type="ALERT_TYPES.warning"
                  class="w-100 mb-3"
                  data-test-id="alert-outside-working-hours"
                >
                  {{ $t('new_booking.alerts.outside_working_hours') }}
                </AlertComponent>
                <VehicleBookingSummary
                  :vehicle="bookingVehicle"
                  :booking="bookedTime"
                  :location="bookingLocation"
                  :operator="reservationOperator"
                  :is-outside-working-hours="isOutsideWorkingHours"
                />
              </div>
            </div>
          </div>
          <div class="Booking__summaryColumn">
            <BookingSummaryFormComponent
              ref="BookingSummaryFormComponent"
              :is-payment-ready="isPaymentMethodValid"
              :loading="isPaymentLoading"
              :map-manager="mapManager"
              @create-booking="onClickCreateReservation"
            >
              <PaymentMethodsHub
                :action="ACTIONS.select"
                :provider="adyenProviderVersion"
                :handle-external-response="PaymentResponse"
                :select-in-background="hidePaymentMethod"
                :add-on-select-is-available="addOnSelectIsAvailable"
                embedded
                @update:payment-method-valid="onPaymentMethodValid"
                @update:no-default-payment-method="onNoDefaultPaymentMethod"
                @update:launch-add-payment-method="onLaunchAddPaymentMethod"
                @on:add-payment-method-authorised="onAddPaymentMethodAuthorised"
                @on:close="onCloseAddPaymentMethod"
                @on:payment-error="setErrorMsg"
              />

              <PaymentMethodsHubListener
                @on:payment-authorised="onPaymentAuthorised"
                @on:payment-refused="onPaymentRefused"
                @close:feedback-modal="onCloseFeedbackModal"
              />

              <FeedbackModalComponent
                v-model="modals.active.isOpen"
                v-bind="modals.active"
              />
            </BookingSummaryFormComponent>
          </div>
        </div>
      </div>

      <InsuranceInfoModal
        v-bind="insuranceInfoModal"
        :is-open="insuranceInfoModal.isOpen"
        @on:close="() => insuranceInfoModal.isOpen = false"
      />
    </template>
  </MainViewLayout>
</template>

<script>
import {
  camelCaseKeys, LOG_TYPE, logger, navigationErrorHandler, toQueryString,
} from '@emobg/web-utils';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { useGtm } from '@gtm-support/vue2-gtm';
import { external } from '@emobg/web-api-client';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import size from 'lodash/size';
import pick from 'lodash/pick';
import PaymentInvoiceApi from '@Bookings/Summary/adyen/PaymentInvoiceApi';

import cfg from '@/config';

import MainViewLayout from '@/templates/MainLayout/MainLayout';
import VehicleBookingSummary from '@/vue/components/organism/Vehicle/VehicleBookingSummary';
import BookingSummaryFormComponent from '@/vue/components/organism/BookingSummary/BookingSummaryFormComponent';
import InsuranceInfoModal from '@/components/Modals/InsuranceInfoModal/InsuranceInfoModal';

import AlertComponent from '@/components/Alert/AlertComponent';

import { useAvailability } from '@/composable/Availability/useAvailability';
import { useInsurance } from '@/composable/Insurance/insurance';
import { useStoreModule } from '@/composable/Store/useStoreModule';
import { useTheme } from '@/composable/Theme/useTheme';

import NotificationMixin from '@/mixins/Notifications';

import * as BookingModule from '@/vue/stores/Booking/BookingStore';
import { MapManager } from '@/vue/managers/MapManager';
import * as DateTimeModule from '@/vue/stores/DateTime/DateTimeStore';
import {
  ACTIONS as VehicleUserActions,
  GETTERS as VehicleUserGetters,
  NAMESPACE as VehicleUserNamespace,
} from '@/stores/VehicleUser/VehicleUserModule';

import BookingRoutesNames from '@Bookings/router/routes-names';
import CompanyAdminRoutesNames from '@Profile/Business/CompanyAdmin/router/routes-names';

import { SEGMENT_EVENTS } from '@/vue/constants';

import { ADDITIONAL_DRIVERS_FILTER, ADDITIONAL_PASSENGERS_FILTER } from '@/constants/vehicleUserTypes.const';
import * as BookingCostAllocationModule from '@/stores/CostAllocation/Booking/BookingCostAllocationModule';
import * as CostAllocationDataModule from '@/stores/CostAllocation/DataModule/CostAllocationDataModule';
import * as CostAllocationModule from '@/vue/stores/BookingSummary/CostAllocations';

import LocationHeaderMyBookingComponent from '@/components/Location/LocationHeader/LocationHeaderMyBookingComponent';
import ALERT_TYPES from '@/components/Alert/alertTypes';

import { useSegment } from '@/composable/Segment/segment';
import { fetchCSOperator, operatorPhone } from '@/stores/CSOperator/CSOperatorMapper';
import { setLoaderStatus } from '@Shared/store/Loader/LoaderMapper';
import { companyAllowsAdditionalDriver, companyUuid } from '@/stores/Company/CompanyMapper';
import { companyPays, userUuid } from '@/stores/User/UserData/UserDataMapper';
import {
  canUseCostAllocation,
  getCurrentProfileUuid,
  isBusinessProfileActive,
} from '@/stores/User/Profile/ProfileMapper';
import { headerAddress, headerDates } from '@Bookings/Summary/constants/bookingSummary';
import { composeBookingCreationEvent, composeCloseBookingSummaryEvent } from '@Bookings/Summary/helpers/segment';
import { composeBookingCreation } from '@Bookings/Summary/helpers/gtm';
import {
  bookedTime,
  bookingDriver,
  bookingInsurance,
  bookingLocation,
  bookingSummary,
  bookingSummaryTotalPrice,
  bookingVehicle,
  createBookingRequestData,
  driverBookingOnBehalfProfileUuid,
  isBooking,
  isBookingLocationOpen,
  isBookingOnBehalf,
  isOutsideWorkingHours,
  isPrebooking,
  reservation,
  reservationOperator,
  setReservation,
  setSummary,
  startBooking,
} from '@/stores/Booking/BookingMapper';
import { getBookingConfirmProps } from '@Bookings/Summary/helpers/bookingSummary';
import { GTMEvents, GTMModalEvents } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { mapLocationData } from '@/helpers/user/tracking/ecommerceEvents';
import { getCurrentCity } from '@/stores/City/CityMapper';
import { genericErrorArgs } from '@/constants/defaultModalArgs';
import { BOOKING_STATUS } from '@/constants/bookingStatus.const';

import { nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';
import { USER_STATUS } from '@/constants/userStatus.const';
import { NAMESPACE as UserNamespace } from '@/stores/User/UserModule';
import {
  ACTIONS as UserIdentityCardActions,
  NAMESPACE as UserIdentityCardNameSpace,
} from '@/stores/User/IdentityCard/IdentityCardStore';
import { genericError as genericErrorImage } from '@/utils/publicImages';

import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';

import {
  ACTIONS,
  ADYEN_VERSION_THRESHOLD,
  BOOKING_FLOW,
  BOOKING_PREVIOUS_PAGE,
  BOOKING_RESOURCE_TYPE,
  DEFAULT_PAYMENT_PROVIDER,
  HUB_REDIRECT_FLOW_STATUS,
  PAYMENT_REDIRECTED,
  RESPONSE_STATUS,
  SOURCE_PAGES,
  STORAGE_3DS_DATA,
} from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';

import { commonImplementationHelper } from '@Shared/Payments/PaymentMethodsHub/commonImplementationHelper';

import PaymentMethodsHub from '@Shared/Payments/PaymentMethodsHub/PaymentMethodsHub';
import PaymentMethodsHubListener from '@Shared/Payments/PaymentMethodsHub/PaymentMethodsHubListener';

import { PaymentMethodsHubComposable } from '@Shared/Payments/PaymentMethodsHub/composables/PaymentMethodsHubComposable';

import { checkUrlQueryOnPageLeave, removeUrlQuery } from '@Shared/Payments/PaymentMethodsHub/composables/responseUtils';

import ModalMixin from '@/mixins/Modal';
import EventHandlerMixin from '@/mixins/EventHandler';
import AuthenticatedUserMixin from '@/mixins/AuthenticatedUser';
import isEmpty from 'lodash/isEmpty';
import { webAppColors } from '@/constants/themes';
import { IDENTITY_CARD_KEY_ERRORS } from './components/IdentityCardModal/const/identityCardModal.const';
import IdentityCardModalComponent from './components/IdentityCardModal/IdentityCardModalComponent';

import BookingPaymentBusiness from './adyen/BookingPaymentBusiness';

export default {
  name: 'BookingSummaryPSD2Refactor',

  components: {
    FeedbackModalComponent,
    BookingSummaryFormComponent,
    LocationHeaderMyBookingComponent,
    MainViewLayout,
    VehicleBookingSummary,
    AlertComponent,
    IdentityCardModalComponent,
    InsuranceInfoModal,

    PaymentMethodsHub,
    PaymentMethodsHubListener,
  },

  mixins: [
    EventHandlerMixin,
    ModalMixin,
    AuthenticatedUserMixin,
    NotificationMixin,
  ],

  beforeRouteLeave(to, from, next) {
    /**
     * When we leave the booking summary, set "visitedCsOperator"
     * to null so the returned value it will be the user operator.
     * */
    this.setVisitedCsOperator(null);
    next();
  },

  setup(_props, context) {
    const gtm = useGtm();
    const { notifyError, notify } = useNotifications();
    const { trackSegment: trackSegmentEvent } = useSegment();
    const { resetAvailabilityStore } = useAvailability();
    const { getTranslatedDescriptions } = useInsurance();
    const {
      trackCartEvent,
      trackModalView,
      trackPageView,
    } = useTrackingGTM();
    const { fetchButtonSpecs } = useTheme();

    const {
      getUserIdentityCardValidation,
      isUserIdentityCardValidationError,
      userIdentityCardValidationError,
    } = useStoreModule(`${UserNamespace}/${UserIdentityCardNameSpace}`, {
      state: {
        isUserIdentityCardValidationError: state => state.validation.STATUS.ERROR,
        userIdentityCardValidationError: state => state.validation.error,
      },
      actions: [
        UserIdentityCardActions.getUserIdentityCardValidation,
      ],
    });

    const { checkPaymentIsFinalStatus, setNewResponse, storeData } = commonImplementationHelper(context);

    const { storage, getPaymentMethodsCollection } = PaymentMethodsHubComposable(storeData);

    const { getAPIFromProvider } = PaymentMethodsHubComposable(storeData);

    const providerVersionRegex = /\d/;

    return {
      getTranslatedDescriptions,
      getUserIdentityCardValidation,
      gtm,
      isUserIdentityCardValidationError,
      resetAvailabilityStore,
      trackSegmentEvent,
      trackCartEvent,
      trackModalView,
      trackPageView,
      userIdentityCardValidationError,
      notifyError,
      notify,
      fetchButtonSpecs,
      checkPaymentIsFinalStatus,
      getPaymentMethodsCollection,
      setNewResponse,
      storage,
      webAppColors,

      getAPIFromProvider,
      providerVersionRegex,
    };
  },

  data() {
    return {
      booking: {
        location: null,
        uuid: null,
        invoice: null,
        status: null,
      },
      showUnpaidCompanyModal: false,
      mapManager: new MapManager(),

      payment: {},
      renderComponent: true,
      bookingCreation: {},

      errorMessage: null,
      isPaymentMethodValid: false,
      currentPaymentMethod: null,
      PaymentResponse: null,
      isPaymentLoading: false,
      isAddPaymentMethodActive: false,

      bookingHash: this.$route.params.hash,
      bookingStage: null,

      isUserIdentityCardModalOpen: false,
      isIdentityCardFeedbackModalOpen: false,

      insuranceInfoModal: {
        isOpen: false,
      },

      modals: {
        active: {
          isOpen: false,
        },
      },
    };
  },

  computed: {
    companyPays,
    companyUuid,
    userUuid,

    getCurrentProfileUuid,
    isBusinessProfileActive,
    companyAllowsAdditionalDriver,
    operatorPhone,

    canUseCostAllocation,
    createBookingRequestData,
    reservation,
    reservationOperator,
    bookingVehicle,
    bookingLocation,
    isBookingLocationOpen,
    isBooking,
    isOutsideWorkingHours,
    isPrebooking,
    bookingDriver,
    driverBookingOnBehalfProfileUuid,
    isBookingOnBehalf,
    bookingSummaryTotalPrice,
    bookingSummary,
    bookingInsurance,
    bookedTime,
    getCurrentCity,

    ...mapState(
      BookingCostAllocationModule.NAMESPACE,
      {
        selectedCostAllocations: state => state.COST_ALLOCATION_DATA.currentCostAllocations,
      },
    ),
    ...mapGetters(
      BookingCostAllocationModule.NAMESPACE,
      {
        costAllocationPayload:
          CostAllocationDataModule.GETTERS.apiPayloadCurrentCostAllocation,
      },
    ),
    ...mapState(
      CostAllocationModule.nameSpace,
      {
        costs: state => get(state, 'cost.data.data'),
      },
    ),

    ...mapGetters(
      VehicleUserNamespace,
      {
        getVehicleUsers: VehicleUserGetters.data,
      },
    ),

    ...mapState(UserDataNameSpace, {
      companyStatus: state => get(state, 'userData.company.status'),
    }),

    showAdditionalDrivers() {
      return !this.isBookingLocationOpen && this.companyAllowsAdditionalDriver && !this.isPreBooking;
    },

    driverOrUserUuid() {
      return get(this.reservation, 'driver.uuid', this.reservation.user.uuid);
    },

    isBusinessInCompanyPays() {
      return this.companyPays && this.isBusinessProfileActive;
    },

    isBusinessInEmployeePays() {
      return !this.companyPays && this.isBusinessProfileActive;
    },

    hidePaymentMethod() {
      const isBookingKO = isEmpty(this.bookingSummary) || this.bookingSummary.is_unpriced_tariff;

      return Boolean(isBookingKO || this.isBusinessInCompanyPays);
    },

    addOnSelectIsAvailable() {
      return !this.isBusinessProfileActive || this.isBusinessInEmployeePays;
    },

    adyenProviderVersion() {
      return this.$featureFlag.flags.csrev3522BookingB2C ? 'Adyen/5.59.0' : DEFAULT_PAYMENT_PROVIDER;
    },

    getProviderVersion() {
      const version = this.providerVersionRegex.exec(this.adyenProviderVersion)[0];

      return Number(version) || null;
    },

    AdyenVersionAboveThreshold() {
      return this.getProviderVersion && this.getProviderVersion > ADYEN_VERSION_THRESHOLD;
    },
  },

  async created() {
    this.genericErrorImage = genericErrorImage;
    this.ADDITIONAL_PASSENGERS_FILTER = ADDITIONAL_PASSENGERS_FILTER;
    this.ADDITIONAL_DRIVERS_FILTER = ADDITIONAL_DRIVERS_FILTER;

    this.ALERT_TYPES = ALERT_TYPES;

    this.ACTIONS = ACTIONS;
    this.RESPONSE_STATUS = RESPONSE_STATUS;

    checkUrlQueryOnPageLeave();

    const previousStorageHash = this.storage.get(BOOKING_FLOW.inProcess);

    if (previousStorageHash) {
      this.checkStorageHash(previousStorageHash);
    }

    const { hash } = this.$route.params;

    if (!this.isBooking && !hash) {
      this.renderComponent = false;
      this.$router.replace({ name: BookingRoutesNames.home }).catch(navigationErrorHandler);
      return;
    }

    if (!this.isBooking) {
      this.renderComponent = false;
      const bookingData = this.storage.get(`booking_${hash}`);

      if (!bookingData) {
        this.$router.replace({ name: BookingRoutesNames.home }).catch(navigationErrorHandler);
        return;
      }

      const { reservation: reservationData, bookingSummary: bookingSummaryData, started } = bookingData;

      await this.setReservation(reservationData);

      await this.setSummary(bookingSummaryData);

      await this.startBooking({ started });

      const vehicleUuid = get(bookingSummaryData, 'vehicle.uuid');

      if (vehicleUuid) {
        external.fleetVehicles.getVehicleDetails(vehicleUuid)
          .then(vehicleDetails => {
            this.setReservation({
              vehicle: {
                ...get(bookingSummaryData, 'vehicle', {}),
                ...this.bookingVehicle || {},
                ...vehicleDetails,
              },
            });
          });
      }
    }

    if (this.canUseCostAllocation) {
      const userProfileUuid = this.isBookingOnBehalf ? this.driverBookingOnBehalfProfileUuid : undefined;
      this.fetchBookingCostAllocation({
        companyUuid: this.companyUuid,
        userProfileUuid,
      });

      await this.getCostAllocations({
        companyUuid: this.companyUuid,
        payload: {
          filter: 'booking',
          ...(userProfileUuid ? { user_profile_uuid: userProfileUuid } : {}),
        },
      });
    }

    this.setLoaderStatus(false);

    this.renderComponent = true;

    this.eventHandler.$on(
      this.events.OPEN_INSURANCE_MORE_INFO_MODAL,
      this.openInsuranceMoreInfoModal,
    );
    this.eventHandler.$on(
      this.events.CONFIRM_BOOKING_RESERVATION,
      this.confirmReservation,
    );

    if (this.isBookingOnBehalf) {
      this.employeeDriverNotification = this.createNotification(
        this.$t('views.booking.create_notification', {
          first_name: get(this.bookingDriver, 'user_first_name'),
          last_name: get(this.bookingDriver, 'user_last_name'),
        }),
      );
    }

    this.eventHandler.$on(
      this.events.SHOW_MUI_NOTIFICATION_COST_ALLOCATION,
      this.showNotification,
    );
  },

  beforeMount() {
    this.headerAddress = headerAddress({ reservation: this.reservation });
    this.headerDates = headerDates({ reservation: this.reservation });
  },

  async mounted() {
    const currencyCode = await this.getCurrencyCode();
    this.trackCartEvent({
      bookingData: this.reservation,
      city: this.getCurrentCity,
      cityFallback: this.bookingSummary,
      currency: currencyCode,
      location: mapLocationData(get(this, 'reservation.location')),
      vehicles: [get(this, 'reservation.vehicle')],
      discount: get(this, 'bookingSummary.price.promotions_subtotal'),
      eventName: GTMEvents.beginCheckout,
    });
  },

  beforeDestroy() {
    this.eventHandler.$off(this.events.OPEN_INSURANCE_MORE_INFO_MODAL);
    this.eventHandler.$off(this.events.CONFIRM_BOOKING_RESERVATION);

    this.eventHandler.$off(
      this.events.SHOW_MUI_NOTIFICATION_COST_ALLOCATION,
      this.showNotification,
    );

    this.removeStorage();
  },

  methods: {
    setLoaderStatus,
    fetchCSOperator,
    getBookingConfirmProps,
    setReservation,
    setSummary,
    startBooking,

    ...mapActions(CostAllocationModule.nameSpace, {
      getCostAllocations: CostAllocationModule.ACTIONS.getCostAllocations,
    }),

    disableBookingButton() {
      this.setIsPaymentLoading(false);
      this.setIsPaymentValid(false);
    },

    enableBookingButton() {
      this.setIsPaymentLoading(false);
      this.setIsPaymentValid(true);
    },

    async cancelPendingBooking() {
      if (this.booking?.uuid) {
        await PaymentInvoiceApi.cancelPendingBooking(this.booking.uuid, {
          userProfileUuid: this.getCurrentProfileUuid,
          userUuid: this.userUuid,
        });
      }
    },

    ...mapActions(
      BookingCostAllocationModule.NAMESPACE,
      {
        fetchBookingCostAllocation:
          BookingCostAllocationModule.ACTIONS.fetchBookingCostAllocation,
      },
    ),
    ...mapActions(VehicleUserNamespace, {
      updateVehicleUserDrivers: VehicleUserActions.updateDrivers,
    }),
    ...mapMutations(BookingModule.nameSpace, {
      resetBookingState: BookingModule.MUTATIONS.RESET_STATE,
    }),

    ...mapMutations(DateTimeModule.nameSpace, {
      resetDateTimeState: DateTimeModule.MUTATIONS.RESET_STATE,
    }),

    async goBack() {
      const currencyCode = await this.getCurrencyCode();

      const eventData = composeCloseBookingSummaryEvent({
        reservation: this.reservation,
        currencyCode,
        bookingSummaryTotalPrice: this.bookingSummaryTotalPrice,
        paymentMethodCollection: await this.getPaymentMethodsCollection(),
      });

      this.trackSegmentEvent({
        name: SEGMENT_EVENTS.CLOSE_RESERVATION_SUMMARY,
        data: eventData,
      });

      this.trackCartEvent({
        bookingData: this.reservation,
        city: this.getCurrentCity,
        cityFallback: this.bookingSummary,
        currency: currencyCode,
        location: mapLocationData(get(this, 'reservation.location')),
        vehicles: [get(this, 'reservation.vehicle')],
        discount: get(this, 'bookingSummary.price.promotions_subtotal'),
        eventName: GTMEvents.removeFromCart,
      });

      const backPage = this.storage.get(BOOKING_PREVIOUS_PAGE);

      if (backPage) {
        this.$router.push(backPage);
      } else {
        this.$router.back();
      }
      this.storage.remove(BOOKING_PREVIOUS_PAGE);

      this.startBooking({ started: false });
    },

    async onClickCreateReservation() {
      await this.getUserIdentityCardValidation({
        userUuid: this.driverOrUserUuid,
        locationUuid: this.reservation.location.uuid,
      });

      if (this.isUserIdentityCardValidationError) {
        const errorUserIdentityCardValidationHandler = {
          [IDENTITY_CARD_KEY_ERRORS.identityCardRequired]: () => { this.isUserIdentityCardModalOpen = true; },
        };
        const defaultErrorHandler = () => { this.isIdentityCardFeedbackModalOpen = true; };
        const errorKey = get(this.userIdentityCardValidationError, 'key');
        const errorHandler = get(errorUserIdentityCardValidationHandler, errorKey, defaultErrorHandler);
        errorHandler();
        return;
      }

      if (this.companyStatus === USER_STATUS.unpaid) {
        this.showUnpaidCompanyModal = true;
        this.setIsPaymentLoading(false);

        return;
      }

      this.setIsPaymentLoading(true);

      if (this.storage.get(BOOKING_FLOW.inProcess)) {
        this.initPayment(this.booking);
      } else {
        try {
          const response = await this.createBooking();

          this.initPayment(response);
        } catch (error) {
          this.bookingStage = BOOKING_FLOW.stage.error;

          this.disableBookingButton();
          this.setErrorMsg(error.message);
        }
      }
    },

    async createBooking() {
      const passengers = this.getVehicleUsers(
        this.ADDITIONAL_PASSENGERS_FILTER,
      );

      const passengerList = map(passengers, 'data');
      const costAllocations = size(this.costAllocationPayload)
        ? this.costAllocationPayload
        : [];
      const bookingParams = this.createBookingRequestData();

      const reservationRequest = {
        ...bookingParams,
        company_cost_allocations: costAllocations,
        passengers: passengerList,
      };

      let booking = null;

      try {
        booking = await BookingPaymentBusiness.createPendingBooking(
          reservationRequest,
        );
      } catch (error) {
        this.bookingStage = BOOKING_FLOW.stage.error;

        this.enableBookingButton();
        const errorMessage = get(
          error,
          'response.data.message',
          this.$t('carsharing_personal.error.booking_no_created'),
        );
        this.trackBookingCanNotBeCreatedStep(errorMessage);

        throw Error(errorMessage);
      }

      this.storage.add(BOOKING_FLOW.inProcess, this.bookingHash);

      return booking;
    },

    initPayment(bookingAnswer) {
      this.booking = bookingAnswer;

      if (!this.booking) {
        return;
      }

      this.setErrorMsg(null);

      if (
        this.booking?.status === BOOKING_STATUS.pendingPayment
      ) {
        this.submitPayment();
      } else {
        this.onPaymentAuthorised(this.booking, true);
      }
    },

    onPaymentMethodValid(method) {
      if (!this.isAddPaymentMethodActive) {
        this.setIsPaymentValid(method.isValid);
        this.setCurrentPaymentMethod(method);
        this.setErrorMsg(null);

        this.onSelectNewPaymentMethod();
      }
    },

    onNoDefaultPaymentMethod() {
      this.setErrorMsg(this.$t('notifications.whooops'));
    },

    onLaunchAddPaymentMethod() {
      this.isAddPaymentMethodActive = true;
      this.disableBookingButton();
    },

    onAddPaymentMethodAuthorised(method) {
      this.setIsPaymentValid(method.providerStatus === RESPONSE_STATUS.authorised);
      this.enableBookingButton();
    },

    onCloseAddPaymentMethod() {
      this.isAddPaymentMethodActive = false;
      this.enableBookingButton();
    },

    setErrorMsg(message) {
      if (message !== this.errorMessage) {
        this.notifyError({
          text: message,
          textAction: this.$t('modal.booking_confirm.ok'),
        });
      }
      this.errorMessage = message;
    },

    setIsPaymentValid(isValid) {
      this.isPaymentMethodValid = isValid;
    },

    setIsPaymentLoading(isLoading) {
      this.isPaymentLoading = isLoading;
    },

    setCurrentPaymentMethod(method) {
      this.currentPaymentMethod = method;
    },

    async submitPayment() {
      this.setErrorMsg(null);

      const { isValid } = this.currentPaymentMethod;

      if (!isValid) {
        return;
      }

      const { securityCode } = this.currentPaymentMethod.paymentMethod;

      const invoiceUuid = this.booking.invoice.uuid;
      const redirect = `${window.location.href}?${HUB_REDIRECT_FLOW_STATUS.fromRedirectQueryString}`;

      const queryString = toQueryString({
        invoice_uuid: invoiceUuid,
        redirect,
      });

      const returnUrl = `${cfg.data.phoenixApiUrl}/payments/checkout${queryString}`;

      const request = {
        ...this.currentPaymentMethod,
        securityCode,
        returnUrl,
      };

      this.setIsPaymentLoading(true);

      let originalResponse = {};

      const api = await this.getAPIFromProvider(this.adyenProviderVersion);

      try {
        if (!this.AdyenVersionAboveThreshold) {
          originalResponse = await api.postBookingInitPayment(invoiceUuid, request);
        } else {
          const invoiceRequest = {
            ...this.currentPaymentMethod,
            paymentMethodUuid: this.currentPaymentMethod.paymentMethod.uuid,
            invoiceUuid,
            resourceType: BOOKING_RESOURCE_TYPE,
            resourceUuid: this.booking.uuid,
          };

          originalResponse = await api.payInvoice(invoiceRequest);
        }
        this.bookingStage = BOOKING_FLOW.stage.init;
      } catch (error) {
        logger.message(error, LOG_TYPE.error);
        this.bookingStage = BOOKING_FLOW.stage.error;

        originalResponse.providerStatus = RESPONSE_STATUS.error;
        originalResponse.providerMessage = error.response.data.message;
      }

      removeUrlQuery(window.location.href, window.location.search);

      const response = camelCaseKeys(originalResponse);

      if (response.providerStatus === RESPONSE_STATUS.refused) {
        this.setErrorMsg(response.providerMessage);
      }

      if (
        response.providerStatus === RESPONSE_STATUS.pending
        && !this.$router.history.current.fullPath.includes(HUB_REDIRECT_FLOW_STATUS.toRedirect)
      ) {
        this.storage.add(`bookingHub_${this.bookingHash}`, this.booking);
        this.$router.push({ query: { status: HUB_REDIRECT_FLOW_STATUS.toRedirect } }).catch(navigationErrorHandler);
      }

      const paymentNotAllowedInB2B = [
        RESPONSE_STATUS.error,
        RESPONSE_STATUS.cancelled,
      ];

      if (paymentNotAllowedInB2B.includes(response.providerStatus) && this.hidePaymentMethod) {
        this.disableBookingButton();

        const errorMessage = this.$t('common.error.redirect_psd2_not_processed', this.$t('notifications.whooops'), { phone: this.operatorPhone });
        this.setErrorMsg(errorMessage);

        return;
      }

      const matchingResponse = {
        ...response,
        matchingName: this.booking.uuid,
        matchingType: SOURCE_PAGES.booking,
        bookingInvoiceUuid: this.booking.invoice.uuid,
        invoiceUuid: this.AdyenVersionAboveThreshold ? this.booking.invoice.uuid : null,
      };

      const hierarchy = {
        ancestor: SOURCE_PAGES.booking,
        parent: this.bookingUuid,
      };

      const finalResponse = this.setNewResponse(matchingResponse, response.providerStatus, hierarchy);

      if (isNull(finalResponse)) {
        this.setIsPaymentLoading(false);
      } else {
        this.PaymentResponse = finalResponse;
      }
    },

    async onPaymentAuthorised(response, direct = false) {
      this.bookingStage = BOOKING_FLOW.stage.authorised;

      if (
        this.hasInvoiceToResolve()
        && !direct
        && !this.AdyenVersionAboveThreshold
      ) {
        const paymentReference = response.authorised?.reference || response.reference;
        this.resolveBookingPayment(paymentReference);
      }

      const bookingCreation = this.bookingCreatedStep();

      this.bookingCreation = await this.additionalDriverStep(bookingCreation);

      this.showModalBookingCreated();

      if (this.storage.get(PAYMENT_REDIRECTED)) {
        this.storage.remove(PAYMENT_REDIRECTED);
      }

      this.trackModalView({ modalName: GTMModalEvents.bookingCreated });

      this.removeStorage();
    },

    onPaymentRefused(response) {
      this.bookingStage = BOOKING_FLOW.stage.refused;

      if (this.storage.get(PAYMENT_REDIRECTED) || response.loopOrigin === RESPONSE_STATUS.challenge) {
        this.showRefusedFeedback(response);

        this.storage.remove(PAYMENT_REDIRECTED);
      } else {
        this.setErrorMsg(response.providerMessage);
      }

      if (!this.hidePaymentMethod) {
        this.disableBookingButton();
      } else {
        this.enableBookingButton();
      }
    },

    showRefusedFeedback(response) {
      this.modals.active = {
        ...genericErrorArgs(this.$t),
        title: response.providerMessage || this.$t('modal.confirm_payment.error.title'),
        description: '',
        isOpen: true,
        primaryCallToAction: () => {
          this.modals.active = {};

          this.modals.active.isOpen = false;
        },
      };
    },

    onCloseFeedbackModal(responseStatus) {
      if (responseStatus === RESPONSE_STATUS.authorised) {
        this.confirmReservation();
      }
    },

    removeStorage(hash = this.bookingHash) {
      logger.message(`removing storage for bookingHash ${hash}`, LOG_TYPE.warning);

      this.storage.remove(`bookingHub_${hash}`);
      this.storage.remove(`booking_${hash}`);
      this.storage.remove(BOOKING_FLOW.inProcess);
      this.storage.remove(STORAGE_3DS_DATA);
    },

    async checkStorageHash(previousStorageHash) {
      if (!this.bookingHash) {
        return;
      }

      if (previousStorageHash !== this.bookingHash) {
        logger.message(`wrong hash used: ${previousStorageHash}, removing...`, LOG_TYPE.warning);
        this.removeStorage(previousStorageHash);

        return;
      }

      if (this.$route.query.status !== HUB_REDIRECT_FLOW_STATUS.fromRedirect) {
        logger.message('no fromRedirect query', LOG_TYPE.warning);

        this.booking = this.storage.get(`bookingHub_${this.bookingHash}`);
        this.storage.remove(BOOKING_FLOW.inProcess);

        await this.cancelPendingBooking();

        return;
      }

      this.booking = this.storage.get(`bookingHub_${this.bookingHash}`);
    },

    hasInvoiceToResolve() {
      return get(this.booking, 'invoice.uuid');
    },

    async resolveBookingPayment(paymentReference) {
      const bookingUuid = this.booking.uuid;

      const request = {
        paymentReference,
        userProfileUuid: this.currentProfile.uuid,
        invoiceUuid: this.booking.invoice.uuid,
        userUuid: this.userData.getUUID(),
      };

      const api = await this.getAPIFromProvider(this.adyenProviderVersion);

      try {
        await api.putBookingResolvePayment(bookingUuid, request);
      } catch (error) {
        this.bookingStage = BOOKING_FLOW.stage.error;

        logger.message(error, LOG_TYPE.error);
      }
    },

    async showModalBookingCreated() {
      const bookingConfirm = await getBookingConfirmProps({
        location: get(this.bookingCreation, 'response.location', {}),
        isPreBooking: this.isPreBooking,
      });

      this.modals.active = {
        ...bookingConfirm,
        primaryCallToAction: () => {
          this.closeBookingConfirmModal();
        },
        primaryCallToActionText: this.$t('buttons.got_it'),
        isOpen: true,
      };
    },

    openInsuranceMoreInfoModal() {
      if (!this.bookingInsurance) {
        return;
      }

      const insurances = [this.bookingInsurance.current_insurance]
        .concat(this.bookingInsurance.upsell_insurances);
      const operatorName = get(this, 'reservation.operator.name');
      const insurancesTranslated = this.getTranslatedDescriptions(operatorName, insurances);

      this.insuranceInfoModal = { ...this.insuranceInfoModal, isOpen: true, insurancesInfo: insurancesTranslated };
      this.trackModalView({ modalName: GTMModalEvents.bookingSummaryInsurancesInfo });
    },

    confirmReservation() {
      const { isPrebookingRightNow } = this.bookingCreation;

      let route = { name: BookingRoutesNames.myBookings };
      if (this.isBookingOnBehalf) {
        route = this.isPreBooking && !isPrebookingRightNow
          ? { name: CompanyAdminRoutesNames.employeeBookingRequests }
          : { name: CompanyAdminRoutesNames.employeeBookingsList };
      }

      this.$router.push(route, () => {
        this.resetBookingState();
        this.resetAvailabilityStore();
        this.resetDateTimeState();
      });
    },

    onChangeProfile() {
      this.$router.replace({ name: BookingRoutesNames.home }).catch(navigationErrorHandler);
    },

    async onSelectNewPaymentMethod() {
      const currencyCode = await this.getCurrencyCode();
      this.trackCartEvent({
        bookingData: this.reservation,
        city: this.getCurrentCity,
        cityFallback: this.bookingSummary,
        currency: currencyCode,
        paymentType: get(this, 'bookingSummary.payment_method.type'),
        location: mapLocationData(get(this, 'reservation.location')),
        vehicles: [get(this, 'reservation.vehicle')],
        discount: get(this, 'bookingSummary.price.promotions_subtotal'),
        eventName: GTMEvents.addPaymentInfo,
      });
    },

    showNotification(params) {
      this.notify(params);
    },

    async trackBookingCreation(params = {}) {
      const { bookingCreated, unsuccessfulReason, bookingUuid } = params;
      const currencyCode = await this.getCurrencyCode();

      const eventData = composeBookingCreationEvent({
        reservation: this.reservation,
        bookingSummary: this.bookingSummary,
        created: bookingCreated,
        errorMessage: unsuccessfulReason,
        bookingUuid,
        currencyCode,
      });

      this.trackSegmentEvent({
        name: SEGMENT_EVENTS.CARSHARING_BOOKING_CREATED,
        data: eventData,
      });

      if (bookingCreated) {
        this.gtm.trackEvent({
          event: GTMEvents.carsharingBookingCreated,
          event_data: eventData,
        });

        this.gtm.trackEvent({
          event: GTMEvents.purchase,
          ecommerce: composeBookingCreation({
            ...camelCaseKeys(pick(this.bookingSummary, ['price', 'booking', 'payment_method', 'location'])),
            ...pick(this.reservation, ['vehicle', 'profile']),
            currencyCode,
            bookingUuid,
          }),
        });
      }
    },

    closeBookingConfirmModal() {
      this.confirmReservation();
      this.modals.active.isOpen = false;
      this.trackPageView();
    },

    trackBookingCanNotBeCreatedStep(errorMessage) {
      this.trackBookingCreation({ bookingCreated: false, unsuccessfulReason: errorMessage });
    },

    bookingCreatedStep() {
      const bookingUuid = get(this, 'booking.uuid');
      this.trackBookingCreation({ bookingCreated: true, bookingUuid });

      return {
        response: this.booking,
      };
    },

    async additionalDriverStep(bookingCreation) {
      const driversEmails = this.getVehicleUsers(
        this.ADDITIONAL_DRIVERS_FILTER,
      );
      const emails = map(driversEmails, 'data');
      const shouldUpdateDrivers = this.showAdditionalDrivers && size(emails);
      const payload = { bookingUuid: get(bookingCreation, 'response.uuid'), emails };

      return !shouldUpdateDrivers
        ? Promise.resolve(bookingCreation)
        : this.updateVehicleUserDrivers(payload)
          .then(() => bookingCreation)
          .catch(error => {
            const errorKey = get(error, 'response.data.key');
            const defaultError = this.$t('carsharing_personal.error.default_additional_drivers');

            return {
              alert: this.$t(`carsharing_personal.error.${errorKey}`, defaultError),
              ...bookingCreation,
            };
          });
    },

    async getCurrencyCode() {
      let operator = null;
      let currencyCode = null;

      if (!this.reservationOperator) {
        return null;
      }

      try {
        operator = await this.fetchCSOperator(get(this.reservationOperator, 'uuid'));

        currencyCode = get(operator, 'configuration.currency_code');
      } catch (error) {
        logger.message(error.message, LOG_TYPE.error);
      }

      return currencyCode;
    },
  },
};
</script>
