<template>
  <MainViewLayout>
    <template slot="main-content">
      <div class="d-flex flex-column px-6">
        <div
          class="d-flex align-items-baseline py-6"
          data-test-id="logbook-view"
        >
          <ui-icon
            :icon="ICONS.arrowBack"
            :size="ICONS_SIZES.small"
            :color="COLORS.primary"
            class="cursor-pointer"
            hover
            data-test-id="navigate_back-icon"
            @click="$router.push({ name: BookingRoutesNames.myBookingsLogbook })"
          />
          <div class="emobg-font-weight-bold emobg-font-large ml-2">
            {{ $t('LogbookView.buttons.go_to_list') }}
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column w-50 mr-6">
            <div class="d-flex">
              <h1 class="emobg-font-large emobg-color-ink">
                {{ $t('LogbookView.title') }}
              </h1>
              <div class="d-flex ml-auto align-items-middle">
                <ui-button
                  v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
                  :disabled="isLoadingBulkSaving || isSomeTripSaving"
                  :size="SIZES.large"
                  data-test-id="toggle_merge_trips-button"
                  @clickbutton="toggleMergeTrips"
                >
                  {{ isMergeEnabled
                    ? $t('buttons.cancel')
                    : $t('LogbookView.buttons.merge.merge_trips')
                  }}
                </ui-button>
                <ui-button
                  v-if="isMergeEnabled"
                  v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
                  :disabled="size(selectedTrips) < 2 || isLoadingBulkSaving || isSomeTripSaving"
                  :size="SIZES.large"
                  data-test-id="submit_merge_trips-button"
                  @clickbutton="() => modal = { ...modalsValues.mergeModal, isOpen: true }"
                >
                  {{ $t('LogbookView.buttons.merge.submit_merge_trips') }}
                </ui-button>
                <ui-button
                  v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
                  :size="SIZES.large"
                  data-test-id="toggle_collapse-button"
                  @clickbutton="() => bookingTripsData = map(bookingTripsData, trip => ({ ...trip, isOpen: !isSomeTripOpen }))"
                >
                  {{ $t(`buttons.${isSomeTripOpen ? 'collapse_all' : 'expand_all'}`) }}
                </ui-button>
                <ui-badge
                  v-if="size(bookingTripsData)"
                  :color="size(validTrips) === size(bookingTripsData)
                    ? COLORS.success
                    : COLORS.warning"
                  class="ml-2 emobg-font-medium mt-2"
                  data-test-id="valid_trips-badge"
                >
                  <span>
                    {{ size(validTrips) }} / {{ size(bookingTripsData) }}
                  </span>
                </ui-badge>
              </div>
            </div>
            <div>
              <div
                v-if="isLoading"
                class="d-flex flex-column"
              >
                <GenericCardWrapperComponent
                  v-for="num in 4"
                  :key="num"
                  data-test-id="logbook_trip_placeholder-card"
                  class="w-100"
                >
                  <template #header>
                    <ui-skeleton
                      height="40"
                      class="w-100"
                    />
                  </template>
                </GenericCardWrapperComponent>
              </div>
              <div
                v-else
                class="d-flex flex-column"
              >
                <LogbookTrip
                  v-for="(trip, index) in bookingTripsData"
                  :key="trip.uuid"
                  v-bind="trip"
                  :trip-num="index + 1"
                  :availaible-reasons="availaibleReasons"
                  :is-merge-enabled="isMergeEnabled"
                  :is-loading="isLoading || isLoadingBulkSaving || isSomeTripSaving"
                  data-test-id="logbook_trip-component"
                  @on:update:trip="value => updateTrip(trip, value)"
                  @on:save:trip="() => saveTrip(trip)"
                  @toggle:is-open="() => trip.isOpen = !trip.isOpen"
                  @toggle:is-selected="() => trip.isSelected = !trip.isSelected"
                />
              </div>
            </div>
          </div>
          <div class="d-flex flex-column w-50">
            <div class="d-flex ml-auto align-items-baseline">
              <ui-button
                v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
                :disabled="isMergeEnabled || isLoading || isSomeTripSaving || !size(changedTrips)"
                :size="SIZES.large"
                :loading="isLoadingBulkSaving"
                data-test-id="save_all_trips-button"
                class="mr-2"
                @clickbutton="saveAllTrips"
              >
                {{ $t('buttons.save_changes') }}
              </ui-button>
              <ui-button
                v-bind="fetchButtonSpecs()"
                :disabled="isMergeEnabled || !areAllTripsValid || isSomeTripSaving || isLoadingBulkSaving"
                :size="SIZES.large"
                data-test-id="submit-button"
                @clickbutton="() => modal = { ...modalsValues.submitModal, isOpen: true }"
              >
                {{ $t('buttons.submit') }}
              </ui-button>
            </div>

            <GoogleMaps
              v-if="mapManager"
              :manager="mapManager"
              style="max-height: 532px;"
              class="mt-4 px-6"
              data-test-id="logbook-map"
              @ready:map="refreshMap"
            />
            <ui-skeleton
              v-else
              class="w-100"
            />
          </div>
        </div>
      </div>
      <FeedbackModalComponent
        :is-open="modal.isOpen"
        v-bind="modal"
      />
    </template>
  </MainViewLayout>
</template>

<script>
import some from 'lodash/some';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import first from 'lodash/first';
import keys from 'lodash/keys';
import set from 'lodash/set';
import clone from 'lodash/clone';
import size from 'lodash/size';
import isArray from 'lodash/isArray';
import uniqWith from 'lodash/uniqWith';
import tail from 'lodash/tail';
import each from 'lodash/each';
import get from 'lodash/get';
import slice from 'lodash/slice';
import last from 'lodash/last';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import assign from 'lodash/assign';
import find from 'lodash/find';
import {
  DELAY,
  LOG_TYPE,
  logger,
  navigationErrorHandler,
} from '@emobg/web-utils';

import { MapManager } from '@/vue/managers/MapManager';
import { instance as svgParkingsMarkers } from '@/services/MapMarkerSelector';

import {
  availaibleReasons,
  bookingTrips,
  bookingWayPoints,
  fetchLogbooksTrips,
  mergeLogbookTrips,
  saveLogbookTrips,
} from '@/stores/Logbook/LogbookMapper';

import MainViewLayout from '@/templates/MainLayout/MainLayout';
import LogbookTrip from '@/components/Logbook/LogbookTrip';
import GoogleMaps from '@Shared/components/Map/Map';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import GenericCardWrapperComponent from '@/components/GenericCardWrapper/GenericCardWrapperComponent';

import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { genericConfirmArgs, genericWarningConfirmArgs } from '@/constants/defaultModalArgs';

import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { useTheme } from '@/composable/Theme/useTheme';

import { areTripsEqual, validateTrip } from './helpers/validators';
import { sanitizeTrip } from './helpers/transformers';
import { LOGBOOK_TRIP_FIELDS } from './constants/logbookTrip';
import BookingRoutesNames from '../router/routes-names';

export default {
  name: 'LogbookView',

  components: {
    MainViewLayout,
    LogbookTrip,
    GenericCardWrapperComponent,
    GoogleMaps,
    FeedbackModalComponent,
  },

  props: {
    bookingUuid: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { notifyError, notifySuccess } = useNotifications();
    const { fetchButtonSpecs } = useTheme();
    return {
      notifyError,
      notifySuccess,
      fetchButtonSpecs,
    };
  },
  data() {
    return {
      isLoading: false,
      isLoadingBulkSaving: false,
      isMergeEnabled: false,
      mapManager: null,
      modal: {
        isOpen: false,
      },
      bookingTripsOriginal: [],
      bookingTripsData: [],
    };
  },

  computed: {
    bookingTrips,
    availaibleReasons,
    bookingWayPoints,

    isSomeTripOpen() {
      return some(map(this.bookingTripsData, 'isOpen'));
    },

    isSomeTripSaving() {
      return some(map(this.bookingTripsData, 'isLoadingSaveTrip'));
    },

    areAllTripsValid() {
      return reduce(
        map(this.bookingTripsData, 'isValid'),
        (accumulator, isValidTrip) => accumulator && isValidTrip,
      );
    },

    validTrips() {
      return filter(this.bookingTripsData, 'isValid');
    },

    selectedTrips() {
      return filter(this.bookingTripsData, 'isSelected');
    },

    changedTrips() {
      return filter(this.bookingTripsData, 'hadChanged');
    },
  },

  created() {
    this.modalsValues = {
      mergeModal: {
        ...genericWarningConfirmArgs(this.$t),
        title: this.$t('LogbookView.modals.merge.title'),
        description: this.$t('LogbookView.modals.merge.description'),
        secondaryCallToAction: () => { this.modal.isOpen = false; },
        primaryCallToAction: this.mergeTrips,
      },
      submitModal: {
        ...genericConfirmArgs(this.$t),
        title: this.$t('LogbookView.modals.submit.title'),
        description: this.$t('LogbookView.modals.submit.description'),
        primaryCallToAction: this.submitLogbook,
        primaryCallToActionText: this.$t('buttons.save'),
        secondaryCallToAction: () => { this.modal.isOpen = false; },
        secondaryCallToActionText: this.$t('buttons.no_go_back'),
      },
    };
    this.BookingRoutesNames = BookingRoutesNames;
    this.LOGBOOK_TRIP_FIELDS = LOGBOOK_TRIP_FIELDS;
  },

  async beforeMount() {
    await this.loadBookingTrips();

    this.allBookingWayPoints = map(this.bookingWayPoints, wayPoint => ({ lat: wayPoint.gpsLat, lng: wayPoint.gpsLng }));

    this.mapManager = new MapManager();

    if (isNil(this.bookingUuid)) {
      this.$router.push({ name: BookingRoutesNames.myBookingsLogbook }).catch(navigationErrorHandler);
    }
  },

  methods: {
    size,
    map,
    fetchLogbooksTrips,
    mergeLogbookTrips,
    saveLogbookTrips,

    toggleMergeTrips() {
      this.isMergeEnabled = !this.isMergeEnabled;
      this.bookingTripsData = map(this.bookingTripsData, trip => ({ ...trip, isSelected: false }));
    },

    updateTrip(trip, newValue) {
      assign(trip, newValue);

      set(trip, 'isValid', validateTrip(trip));

      if (includes(this.LOGBOOK_TRIP_FIELDS, first(keys(newValue)))) {
        const originalTrip = find(this.bookingTripsOriginal, ['uuid', trip.uuid]);
        const hadChanged = !areTripsEqual(trip, originalTrip);
        set(trip, 'hadChanged', hadChanged);
      }
    },

    async loadBookingTrips() {
      this.isLoading = true;
      try {
        await this.fetchLogbooksTrips({ bookingUuid: this.bookingUuid });
        this.bookingTripsOriginal = clone(this.bookingTrips);
        this.bookingTripsData = map(this.bookingTripsOriginal,
          trip => ({
            ...trip,
            isOpen: false,
            isSelected: false,
            isValid: validateTrip(trip),
            isLoadingSaveTrip: false,
            hadChanged: false,
          }));
      } catch (error) {
        const text = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.notifyError({
          text,
          action: () => {
            this.$router.back();
          },
        });
        logger.message(`There was a problem fetching booking trips: ${error}`, LOG_TYPE.warning);
      } finally {
        this.refreshMap();
        this.isLoading = false;
      }
    },

    async mergeTrips() {
      this.isLoading = true;
      this.modal = {
        ...this.modal,
        secondaryCallToActionDisabled: true,
        primaryCallToActionLoading: true,
      };
      this.cleanMap();

      try {
        await this.mergeLogbookTrips({
          bookingUuid: this.bookingUuid,
          trips: {
            trip_uuids: map(this.selectedTrips, trip => get(trip, 'uuid')),
          },
        });

        this.notifySuccess({
          text: this.$t('LogbookView.notifications.merge.successful'),
          delayToClose: DELAY.extraLong,
        });
        this.loadBookingTrips();
      } catch (error) {
        const text = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.notifyError({ text });
        logger.message(`There was a problem fetching booking trips: ${error}`, LOG_TYPE.warning);
      } finally {
        this.isLoading = false;
        this.toggleMergeTrips();
        this.modal = {
          ...this.modal,
          primaryCallToActionLoading: false,
          secondaryCallToActionDisabled: false,
          isOpen: false,
        };
      }
    },

    async saveTrip(trip) {
      set(trip, 'isLoadingSaveTrip', true);
      try {
        const sanitizedTrip = sanitizeTrip(trip);
        await this.saveLogbookTrips({
          bookingUuid: this.bookingUuid,
          data: {
            trips: [sanitizedTrip],
            completed: false,
          },
        });
        this.notifySuccess({
          text: this.$t('LogbookView.notifications.saveTrip.successful'),
          delayToClose: DELAY.extraLong,
        });
        set(trip, 'hadChanged', false);
      } catch (error) {
        const text = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.notifyError({ text });
        logger.message(`There was a problem fetching booking trips: ${error}`, LOG_TYPE.warning);
      } finally {
        set(trip, 'isLoadingSaveTrip', false);
      }
    },

    async saveAllTrips() {
      this.isLoadingBulkSaving = true;
      try {
        const sanitizedTrips = map(this.changedTrips, trip => sanitizeTrip(trip));
        await this.saveLogbookTrips({
          bookingUuid: this.bookingUuid,
          data: {
            trips: sanitizedTrips,
            completed: false,
          },
        });
        this.notifySuccess({
          text: this.$t('LogbookView.notifications.saveAllTrips.successful'),
          delayToClose: DELAY.extraLong,
        });
        this.bookingTripsData = map(this.bookingTripsData, trip => ({ ...trip, hadChanged: false }));
      } catch (error) {
        const text = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.notifyError({ text });
        logger.message(`There was a problem fetching booking trips: ${error}`, LOG_TYPE.warning);
      } finally {
        this.isLoadingBulkSaving = false;
      }
    },

    async submitLogbook() {
      this.modal = {
        ...this.modal,
        secondaryCallToActionDisabled: true,
        primaryCallToActionLoading: true,
      };
      try {
        const sanitizedTrips = map(this.bookingTripsData, trip => sanitizeTrip(trip));
        await this.saveLogbookTrips({
          bookingUuid: this.bookingUuid,
          data: {
            trips: sanitizedTrips,
            completed: true,
          },
        });
        this.$router.push({ name: BookingRoutesNames.myBookingsLogbook }).catch(navigationErrorHandler);
      } catch (error) {
        const text = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.notifyError({ text });
        logger.message(`There was a problem fetching booking trips: ${error}`, LOG_TYPE.warning);
      } finally {
        this.modal = {
          ...this.modal,
          primaryCallToActionLoading: false,
          secondaryCallToActionDisabled: false,
          isOpen: false,
        };
        this.notifySuccess({
          text: this.$t('LogbookView.notifications.submit.successful'),
          delayToClose: DELAY.extraLong,
        });
      }
    },

    async refreshMap() {
      if (!isArray(this.allBookingWayPoints) && !size(this.allBookingWayPoints)) {
        return;
      }

      this.cleanMap();

      const wayPoints = uniqWith(this.allBookingWayPoints, isEqual);

      each(tail(wayPoints), (waypoint, index) => {
        this.mapManager.addAndShowMarker(this.mapManager.createMarker({
          id: `location_between_${index}`,
          lat: get(waypoint, 'lat'),
          long: get(waypoint, 'lng'),
          icon: this.mapManager.constructor.getSvgIconUrl(svgParkingsMarkers.iconTripEnd()),
        }));
      });

      const firstWayPoint = first(wayPoints);
      this.mapManager.addAndShowMarker(this.mapManager.createMarker({
        id: 'location_first',
        lat: get(firstWayPoint, 'lat'),
        long: get(firstWayPoint, 'lng'),
        icon: this.mapManager.constructor.getSvgIconUrl(svgParkingsMarkers.iconTripStart()),
      }));

      const lastWayPoint = last(wayPoints);
      const middleWayPoints = slice(wayPoints, 1, size(wayPoints) - 1);

      await this.mapManager.setCenterMap(get(firstWayPoint, 'lat'), get(firstWayPoint, 'lng'));
      this.mapManager.drawMultiRoute(firstWayPoint, lastWayPoint, middleWayPoints);
    },

    cleanMap() {
      this.mapManager.hideAllMarkers();
      this.mapManager.removeAllParkingMarkersFromList();
      this.mapManager.hideRoute();
    },

  },
};
</script>
