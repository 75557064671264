import { useTranslations } from '@/composable/App/Translations';

import BookingRouteNames from '@Bookings/router/routes-names';

import MyActiveBookingsTabComponent from '@/components/MyBookings/tabs/MyActiveBookingsTabComponent';
import MyPastBookingsTab from '@/components/MyBookings/tabs/MyPastBookingsTab';
import LogBookTab from '@/components/MyBookings/tabs/LogBookTab';

const { $t } = useTranslations();

export const computeMyBookingsTabs = ({
  lastBookingSelected,
  mapManager,
  bookingTypes,
  hasOnlyInterventionType,
  showIconPendingLogbook,
  hasLogBook,
  isBusinessProfileActive,
}) => [
  {
    id: 'active_bookings',
    label: $t('new_booking.tab.active_bookings.title'),
    content: [
      {
        component: MyActiveBookingsTabComponent,
        props: {
          lastBookingSelected,
          mapManager,
          bookingTypes,
        },
      },
    ],
    url: hasOnlyInterventionType ? BookingRouteNames.myInterventionsActive : BookingRouteNames.myBookingsActive,
    isVisible: true,
  },
  {
    id: 'history_bookings',
    label: $t('new_booking.tab.active_bookings.history_bookings'),
    content: [
      {
        component: MyPastBookingsTab,
        props: {
          bookingTypes,
        },
      },
    ],
    url: hasOnlyInterventionType ? BookingRouteNames.myInterventionsHistory : BookingRouteNames.myBookingsHistory,
    isVisible: true,
  },
  {
    id: 'logbook',
    label: {
      title: $t('new_booking.tab.active_bookings.logbook_bookings'),
      icon: showIconPendingLogbook,
    },
    content: [
      {
        component: LogBookTab,
      },
    ],
    url: BookingRouteNames.myBookingsLogbook,
    isVisible: hasLogBook && isBusinessProfileActive && !hasOnlyInterventionType,
  },
];
