import { LOG_TYPE, logger } from '@emobg/web-utils';

export default {

  data() {
    return {
      currentPage: {
        activeBookings: 1,
        historyBookings: 1,
      },
    };
  },

  computed: {
    currentPageType() {
      const result = {};
      Object.keys(this.currentPage).forEach(i => {
        result[i] = i;
      });

      return result;
    },
  },

  methods: {
    getActiveBookingPage() {
      return this.currentPage.activeBookings;
    },

    getHistoryBookingPage() {
      return this.currentPage.historyBookings;
    },

    setActiveBookingPage(num = 1) {
      this.currentPage.activeBookings = num;
    },

    setHistoryBookingPage(num = 1) {
      this.currentPage.historyBookings = num;
    },

    loadActiveBookings() {
      logger.message('You are using My Booking pagination mixin. Method "loadActiveBookings" shoud be override', LOG_TYPE.warning);
    },

    loadHistoryBookings() {
      // eslint-disabled-next-line
      throw new Error('You are using My Booking pagination mixin. Method "loadHistoryBookings" shoud be override');
    },

    goToPage(currentPageType, num) {
      this.currentPage[currentPageType] = num;
      const actives = this.currentPageType.activeBookings === currentPageType;
      const history = this.currentPageType.historyBookings === currentPageType;

      if (actives) {
        this.loadActiveBookings();
      } else if (history) {
        this.loadHistoryBookings();
      }
    },

    addPage(currentPageType, num = 1) {
      this.currentPage[currentPageType] += num;
      const actives = this.currentPageType.activeBookings === currentPageType;
      const history = this.currentPageType.historyBookings === currentPageType;

      if (actives) {
        this.loadActiveBookings();
      } else if (history) {
        this.loadHistoryBookings();
      }
    },

    subtractPage(currentPageType, num = 1) {
      this.currentPage[currentPageType] -= num;
      const actives = this.currentPageType.activeBookings === currentPageType;
      const history = this.currentPageType.historyBookings === currentPageType;

      if (actives) {
        this.loadActiveBookings();
      } else if (history) {
        this.loadHistoryBookings();
      }
    },

    getShowPaginationText({
      itemsPerPage, currentPage, items, totalItems,
    }) {
      return {
        from: (itemsPerPage * (currentPage - 1)),
        to: (itemsPerPage * (currentPage - 1)) + items,
        total: totalItems,
      };
    },

  },
};
