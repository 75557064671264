export const BOOKING_EVENTS = {
  cleanMap: 'CLEAN_MAP',
  createBookingMarkers: 'CREATE_BOOKING_MARKERS',
  createAndFocusSelectedBookingMarker: 'CREATE_AND_FOCUS_SELECTED_BOOKING_MARKER',
  editBookingTimeModalOpen: 'EDIT_BOOKING_TIME_MODAL_OPEN',
  cancelBookingModalOpen: 'CANCEL_BOOKING_MODAL_OPEN',
  submitEditBooking: 'SUBMIT_EDIT_BOOKING',
  editBookingDropOffModalOpen: 'EDIT_BOOKING_DROP_OFF_MODAL_OPEN',
  editBookingPeopleModalOpen: 'EDIT_BOOKING_PEOPLE_MODAL_OPEN',
  editBookingCancelCarPooling: 'EDIT_BOOKING_CANCEL_CAR_POOLING',
  editBookingAddCarpooling: 'EDIT_BOOKING_ADD_CARPOOLING',
  editBookingUpgradeInsurance: 'EDIT_BOOKING_UPGRADE_INSURANCE',
  setLastBookingSelected: 'LAST_BOOKING_SELECTED',
};
