import pick from 'lodash/pick';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import each from 'lodash/each';
import set from 'lodash/set';
import concat from 'lodash/concat';

import { LOGBOOK_PURPOSE_VALUES, LOGBOOK_TRIP_FIELDS, LOGBOOK_TRIP_FIELDS_KEYS } from '../constants/logbookTrip';

/**
 * It sanitises trips according to product rules
 * @param {object} trip
 * @example <caption>Example usage of sanitizeTrip.</caption>
 * // returns  { isOvernight: false, purpose: 'break' ,  exceededMileage: false }
 * const trip = { isOvernight: false, overnightReason: 'something', purpose: 'break' , reason: 'pick up client', exceededMileage: false, clientName: 'melkor' }
 * sanitizeTrip(trip)
 *
 * @return {object} sanitized object
 */
export const sanitizeTrip = trip => {
  const rules = [
    {
      condition: tripData => includes([LOGBOOK_PURPOSE_VALUES.break, LOGBOOK_PURPOSE_VALUES.return], tripData.purpose),
      fields: [LOGBOOK_TRIP_FIELDS_KEYS.reason, LOGBOOK_TRIP_FIELDS_KEYS.clientName, LOGBOOK_TRIP_FIELDS_KEYS.clientAddress],
      replace: '',
    },
    {
      condition: tripData => !tripData.isOvernight,
      fields: [LOGBOOK_TRIP_FIELDS_KEYS.overnightReason],
      replace: null,
    },
  ];

  const tripValues = pick(trip, concat(LOGBOOK_TRIP_FIELDS, 'uuid'));

  const sanitized = reduce(rules, (tripAccumulator, rule) => {
    if (rule.condition(trip)) {
      each(rule.fields, field => set(tripAccumulator, field, rule.replace));
    }
    return tripAccumulator;
  },
  tripValues);
  return sanitized;
};
