<script>
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import invoke from 'lodash/invoke';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import size from 'lodash/size';
import toUpper from 'lodash/toUpper';
import {
  computed,
  getCurrentInstance,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import {
  Validate,
  VALIDATION_EVENT_MODIFIERS,
} from '@emobg/vue-base';
import {
  ARROW_LEFT,
  ARROW_RIGHT,
  BACK_SPACE,
  DELETE,
  END,
  HOME,
  STORE_REQUEST_STATUS,
} from '@emobg/web-utils';
import { NAMESPACE as UserNamespace } from '@/stores/User/UserModule';
import { ACTIONS as CountryActions, NAMESPACE as CountryNameSpace } from '@/stores/Country/CountryStore';
import {
  ACTIONS as UserIdentityCardActions,
  NAMESPACE as UserIdentityCardNameSpace,
  SCOPES as UserIdentityCardScopes,
} from '@/stores/User/IdentityCard/IdentityCardStore';
import { useStoreModule } from '@/composable/Store/useStoreModule';
import { useTheme } from '@/composable/Theme/useTheme';

import {
  IDENTITY_CARD_KEY_ERRORS,
  IDENTITY_TYPE_VALIDATIONS_BY_COUNTRY,
} from './const/identityCardModal.const';

export default {
  name: 'IdentityCardModalComponent',
  directives: {
    Validate,
  },
  props: {
    userUuid: {
      type: String,
      required: true,
    },
    isBookingOnBehalf: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'on:close-modal',
    'on:success',
    'on:error',
  ],
  setup(props, { emit }) {
    const { $t } = getCurrentInstance().proxy;
    const {
      countries,
      countriesLoading,
      fetchCountries,
    } = useStoreModule(CountryNameSpace, {
      state: {
        countries: state => state.data,
        countriesLoading: state => state.STATUS.LOADING,
      },
      actions: [CountryActions.fetchCountries],
    });

    const { fetchButtonSpecs } = useTheme();

    const {
      identityCardFormStatus,
      identityCardFormError,
      identityTypes,
      identityTypesLoading,
      setData: setUserIdentityCardData,
      setStatus: setUserIdentityCardStatus,
      getIdentityTypesByCountry,
      postUserIdentityCard,
    } = useStoreModule(`${UserNamespace}/${UserIdentityCardNameSpace}`, {
      state: {
        identityCardFormError: state => state.form.error,
        identityCardFormStatus: state => state.form.STATUS,
        identityTypes: state => state.identityTypes.data,
        identityTypesLoading: state => state.identityTypes.STATUS.LOADING,
      },
      mutations: ['setData', 'setStatus'],
      actions: [
        UserIdentityCardActions.getIdentityTypesByCountry,
        UserIdentityCardActions.postUserIdentityCard,
      ],
    });

    const idNumberInput = ref(null);
    const isIdNumberInvalid = ref(false);
    const formStatus = ref({});
    const inputs = reactive({
      issuedCountryUuid: '',
      identityType: '',
      value: '',
    });
    const modalHeader = computed(() => (props.isBookingOnBehalf
      ? $t('modal.identity_card.title_booking_on_behalf')
      : $t('modal.identity_card.title')));
    const countrySelected = computed(() => find(countries.value, { uuid: inputs.issuedCountryUuid }));
    const identityTypeValidationsByCountry = computed(() => {
      const countryCode = get(countrySelected.value, 'code', '');
      const countryIdentityTypesValidations = get(IDENTITY_TYPE_VALIDATIONS_BY_COUNTRY, countryCode);
      const {
        format,
        maxLength,
        matchKeys,
        messageKey,
        showOnLength,
        example,
        keyToUpperCase,
      } = get(countryIdentityTypesValidations, inputs.identityType, '');
      return {
        example,
        format,
        maxLength,
        matchKeys,
        messageKey,
        showOnLength,
        keyToUpperCase,
      };
    });
    const idNumberPlaceholder = computed(() => {
      const exampleValue = get(identityTypeValidationsByCountry.value, 'example', '');
      return exampleValue ? `${$t('modal.identity_card.inputs.identity_number.placeholder')} ${exampleValue}` : '';
    });
    const idNumberValidations = computed(() => {
      const {
        format,
        messageKey,
        showOnLength,
      } = identityTypeValidationsByCountry.value;

      return showOnLength
        ? {
          isValidPatternOnLength: value => ({
            isValid: inputs.value.length >= showOnLength ? !!value.match(format) : true,
            message: $t(`modal.identity_card.inputs.identity_number.errors.${messageKey}`),
          }),
          isValidIdNumber: () => ({
            isValid: !isIdNumberInvalid.value,
            message: $t('modal.identity_card.inputs.identity_number.errors.invalid_number'),
          }),
        }
        : {
          isRequired: true,
        };
    });
    const isRequestButtonDisabled = computed(() => {
      const { maxLength } = identityTypeValidationsByCountry.value;
      const isMaxLength = isUndefined(maxLength) ? true : inputs.value.length === maxLength;
      return (!formStatus.value.areAllValidated && !formStatus.value.isFormReady)
        || !isMaxLength
        || identityCardFormStatus.value.ERROR;
    });

    const onKeyDownHandler = event => {
      const allowedKeys = [
        ARROW_LEFT,
        ARROW_RIGHT,
        BACK_SPACE,
        DELETE,
        HOME,
        END,
      ];
      const valueLength = event.target.value.length;
      const {
        maxLength,
        matchKeys,
      } = identityTypeValidationsByCountry.value;

      const isKeyAllowed = includes(allowedKeys, event.keyCode);
      const isMatchedKey = matchKeys && event.key.match(matchKeys);
      const isValueLessThanMaxLength = valueLength + 1 <= maxLength;
      const isTextSelected = event.target.selectionEnd - event.target.selectionStart;
      const isMaxLength = isUndefined(maxLength)
        ? true
        : isValueLessThanMaxLength || isTextSelected;

      if (!(isKeyAllowed || (isMatchedKey && isMaxLength))) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    watch(
      () => inputs.issuedCountryUuid,
      async newCountryUuid => {
        inputs.identityType = '';
        setUserIdentityCardData({
          scope: UserIdentityCardScopes.identityTypes,
          value: [],
        });
        await getIdentityTypesByCountry({
          countryUuid: newCountryUuid,
        });
      },
    );
    watch(
      () => inputs.identityType,
      newIdentityType => {
        inputs.value = '';
        const input = invoke(idNumberInput.value, 'querySelector', 'input');
        invoke(input, 'removeEventListener', 'keydown', onKeyDownHandler);

        nextTick(() => {
          invoke(idNumberInput.value, 'dispatchEvent', new CustomEvent(VALIDATION_EVENT_MODIFIERS.reset, {
            detail: {
              force: true,
            },
          }));
        });

        const {
          maxLength,
          matchKeys,
        } = identityTypeValidationsByCountry.value;
        if (newIdentityType && (maxLength || matchKeys)) {
          invoke(input, 'addEventListener', 'keydown', onKeyDownHandler);
        }
      },
    );
    watch(
      () => inputs.value,
      () => {
        if (isIdNumberInvalid.value && identityCardFormStatus.value.ERROR) {
          isIdNumberInvalid.value = false;
          setUserIdentityCardStatus({
            scope: UserIdentityCardScopes.form,
            value: STORE_REQUEST_STATUS.idle,
          });
        }
      },
    );

    onMounted(async () => {
      if (!size(countries.value)) {
        await fetchCountries();
      }
    });
    onBeforeUnmount(() => {
      setUserIdentityCardData({
        scope: UserIdentityCardScopes.identityTypes,
        value: [],
      });
      const input = invoke(idNumberInput.value, 'querySelector', 'input');
      invoke(input, 'removeEventListener', 'keydown', onKeyDownHandler);
    });

    const requestUserIdCard = async () => {
      await postUserIdentityCard({
        userUuid: props.userUuid,
        data: { ...inputs },
      });

      if (!identityCardFormStatus.value.ERROR) {
        emit('on:success');
        emit('on:close-modal');
        return;
      }

      const { key } = identityCardFormError.value;
      if (key === IDENTITY_CARD_KEY_ERRORS.invalidCardValue) {
        isIdNumberInvalid.value = true;
        invoke(idNumberInput.value, 'dispatchEvent', new CustomEvent(VALIDATION_EVENT_MODIFIERS.validate));
        return;
      }
      emit('on:error');
      emit('on:close-modal');
    };
    const setIdNumberValue = ({ detail: value }) => {
      const { keyToUpperCase } = identityTypeValidationsByCountry.value;
      inputs.value = keyToUpperCase ? toUpper(value) : value;
    };

    return {
      countries,
      countriesLoading,
      countrySelected,
      fetchButtonSpecs,
      formStatus,
      identityCardFormError,
      identityCardFormStatus,
      identityTypes,
      identityTypesLoading,
      identityTypeValidationsByCountry,
      idNumberInput,
      idNumberPlaceholder,
      idNumberValidations,
      inputs,
      isIdNumberInvalid,
      isRequestButtonDisabled,
      map,
      modalHeader,
      requestUserIdCard,
      setIdNumberValue,
      size,
      toUpper,
    };
  },
};
</script>

<template>
  <ui-modal
    :open="true"
    :size="SIZES.small"
    :header="modalHeader"
    data-test-id="identity_card-modal"
  >
    <div
      slot="body"
      class="position-relative"
    >
      <ui-validate @status="({ detail }) => formStatus = detail">
        <ui-loader
          v-if="countriesLoading || identityTypesLoading"
          absolute
        />
        <p class="mb-3">
          <template v-if="isBookingOnBehalf">
            {{ $t('modal.identity_card.description_booking_on_behalf') }}
          </template>
          <template v-else>
            {{ $t('modal.identity_card.description') }}
          </template>
        </p>
        <ui-select
          :value="inputs.issuedCountryUuid"
          :options.prop="map(countries, country => ({ label: country.name, value: country.uuid }))"
          :disabled="!size(countries)"
          :label="`${$t('modal.identity_card.inputs.issuing_country.label')}*`"
          :placeholder="$t('modal.identity_card.inputs.issuing_country.placeholder')"
          class="d-block mb-3"
          name="country-selector"
          @selectoption="({ detail }) => inputs.issuedCountryUuid = detail"
        />
        <ui-select
          :value="inputs.identityType"
          :options.prop="map(
            identityTypes,
            identityType => ({
              label: $t(`modal.identity_card.identity_types.${identityType.translationKey}`),
              value: identityType.identityType
            }),
          )"
          :disabled="!inputs.issuedCountryUuid || !size(identityTypes)"
          :label="`${$t('modal.identity_card.inputs.document_type.label')}*`"
          :placeholder="$t('modal.identity_card.inputs.document_type.placeholder')"
          class="d-block mb-3"
          name="identity_type-selector"
          @selectoption="({ detail }) => inputs.identityType = detail"
        />
        <ui-text-input
          ref="idNumberInput"
          v-validate.input="idNumberValidations"
          :value="inputs.value"
          :disabled="!inputs.identityType"
          :placeholder="idNumberPlaceholder"
          :label="`${$t('modal.identity_card.inputs.identity_number.label')}*`"
          class="d-block"
          name="id_number"
          @changevalue="setIdNumberValue"
        />
      </ui-validate>
    </div>
    <div
      slot="footer"
      class="d-flex justify-content-end"
    >
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
        class="mr-2"
        data-test-id="cancel-button"
        @clickbutton="$emit('on:close-modal')"
      >
        {{ $t('buttons.cancel') }}
      </ui-button>
      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="isRequestButtonDisabled"
        :loading="identityCardFormStatus.LOADING"
        data-test-id="confirm-button"
        @clickbutton="requestUserIdCard"
      >
        {{ $t('modal.identity_card.inputs.confirm_and_book') }}
      </ui-button>
    </div>
  </ui-modal>
</template>
