<template>
  <GenericCardWrapperComponent
    data-test-id="booking_set_summary-card"
    class="w-100"
    :open="isBookingSetSummaryOpen"
  >
    <template #header>
      <div class="d-flex align-items-center mr-auto">
        <ui-icon
          class="mr-2"
          :icon="ICONS.calendar"
          :size="SIZES.small"
        />
        <div class="d-flex justify-content-between">
          <div class="mr-2">
            <span class="emobg-font-weight-bold mr-2">{{ $t('BookingSummary.RecurringBookingSummary.header.recurrenceStart') }}:</span>
            <span class="emobg-color-ink-light">{{ formatDate(head(periods).start, datetimeFormats.shortPrettyDateMonthYear) }}</span>
          </div>
          <div>
            <span class="emobg-font-weight-bold mr-2">{{ $t('BookingSummary.RecurringBookingSummary.header.recurrenceEnd') }}:</span>
            <span class="emobg-color-ink-light">{{ formatDate(last(periods).start, datetimeFormats.shortPrettyDateMonthYear) }}</span>
          </div>
        </div>
        <ui-icon
          class="ml-4 mr-2"
          :icon="ICONS.info"
          :size="ICONS_SIZES.small"
        />
        <span class="emobg-font-weight-bold mr-1">{{ $t('BookingSummary.RecurringBookingSummary.header.bookingsCardinal', {
          number: size(periods)
        }) }}</span>
      </div>
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
        :color="COLORS.inkLight"
        :size="SIZES.small"
        class="p-0 m-0 ml-auto"
        data-test-id="open_set_summary-button"
        @clickbutton="() => isBookingSetSummaryOpen = !isBookingSetSummaryOpen"
      >
        {{ $t('buttons.more_details') }}
        <ui-icon
          :icon="isBookingSetSummaryOpen ? ICONS.arrowUp : ICONS.arrowDown"
          :size="ICONS_SIZES.small"
          class="emobg-body-small"
        />
      </ui-button>
    </template>
    <template #collapsable-content>
      <div class="d-flex flex-column">
        <span class="emobg-font-weight-bold emobg-border-bottom-1 emobg-border-color-ground w-100 mt-4 mb-2">
          {{ $t('BookingSummary.RecurringBookingSummary.collapsableContent.title') }}
        </span>
        <div class="d-flex flex-column flex-wrap emobg-font-small RecurringBookingSummary__content">
          <div
            v-for="(period, index) in periodsChecks"
            :key="index"
            class="d-flex align-items-center w-50"
            data-test-id="booking_set_period-container"
          >
            <ui-checkbox
              :checked="period.selected"
              :size="SIZES.small"
              :disabled="index === 0"
              data-test-id="period-checkbox"
              @changevalue="({ detail }) => handleChangePeriod({ ...period, selected: detail }, index)"
            />
            <div class="d-flex w-100">
              <div class="w-50">
                <span class="emobg-font-weight-bold ml-1">{{ $t('common.booking.start') }}: </span>
                <span
                  class="emobg-color-ink-light ml-1"
                  data-test-id="period_start-text"
                >
                  {{ formatDate(period.start, datetimeFormats.shortPrettyDatetime) }}
                </span>
              </div>
              <div class="w-50">
                <span class="emobg-font-weight-bold ml-2">{{ $t('common.booking.end') }}: </span>
                <span
                  class="emobg-color-ink-light ml-1"
                  data-test-id="period_end-text"
                >
                  {{ formatDate(period.end, datetimeFormats.shortPrettyDatetime) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </GenericCardWrapperComponent>
</template>
<script>
import map from 'lodash/map';
import size from 'lodash/size';
import last from 'lodash/last';
import filter from 'lodash/filter';
import head from 'lodash/head';
import omit from 'lodash/omit';

import { formatDate } from '@emobg/web-utils';

import GenericCardWrapperComponent from '@/components/GenericCardWrapper/GenericCardWrapperComponent';
import { useTheme } from '@/composable/Theme/useTheme';

import { DATETIME_FORMATS } from '@/constants/datetimeFormats';

export default {
  name: 'RecurringBookingSummary',
  components: {
    GenericCardWrapperComponent,
  },
  props: {
    periods: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs };
  },
  data() {
    return {
      isBookingSetSummaryOpen: false,
      periodsChecks: [],
    };
  },
  created() {
    this.datetimeFormats = DATETIME_FORMATS;
    this.periodsChecks = map(this.periods, period => ({ ...period, selected: true }));
  },
  methods: {
    map,
    size,
    filter,
    omit,
    head,
    last,
    formatDate,
    handleChangePeriod(updatedPeriod, index) {
      this.periodsChecks[index] = updatedPeriod;
      this.$emit('update:periods', map(filter(this.periodsChecks, 'selected'), period => omit(period, 'selected')));
    },
  },
};
</script>
<style lang="scss" scoped>
.RecurringBookingSummary__content {
  max-height: 500px;
}
</style>
