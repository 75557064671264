import get from 'lodash/get';

import { useTranslations } from '@/composable/App/Translations';
import { bookingConfirmed } from '@/utils/publicImages';

const { $t } = useTranslations();

export const getBookingConfirmProps = ({ location, isPreBooking }) => {
  const isZone = get(location, 'is_geofence');
  const textKey = isZone ? 'modal.booking_zone_completed.text' : 'modal.booking_confirm.subtitle';
  const titleKey = isPreBooking ? 'modal.prebooking_confirm.title' : 'modal.booking_confirm.title';

  return {
    image: isZone ? get(location, 'image') : bookingConfirmed,
    title: $t(titleKey),
    description: isPreBooking ? null : $t(textKey),
    class: 'p-5',
  };
};
