var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-modal',{attrs:{"open":true,"size":_vm.SIZES.small,"header":_vm.modalHeader,"data-test-id":"identity_card-modal"}},[_c('div',{staticClass:"position-relative",attrs:{"slot":"body"},slot:"body"},[_c('ui-validate',{on:{"status":({ detail }) => _vm.formStatus = detail}},[(_vm.countriesLoading || _vm.identityTypesLoading)?_c('ui-loader',{attrs:{"absolute":""}}):_vm._e(),_c('p',{staticClass:"mb-3"},[(_vm.isBookingOnBehalf)?[_vm._v(" "+_vm._s(_vm.$t('modal.identity_card.description_booking_on_behalf'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('modal.identity_card.description'))+" ")]],2),_c('ui-select',{staticClass:"d-block mb-3",attrs:{"value":_vm.inputs.issuedCountryUuid,"disabled":!_vm.size(_vm.countries),"label":`${_vm.$t('modal.identity_card.inputs.issuing_country.label')}*`,"placeholder":_vm.$t('modal.identity_card.inputs.issuing_country.placeholder'),"name":"country-selector"},domProps:{"options":_vm.map(_vm.countries, country => ({ label: country.name, value: country.uuid }))},on:{"selectoption":({ detail }) => _vm.inputs.issuedCountryUuid = detail}}),_c('ui-select',{staticClass:"d-block mb-3",attrs:{"value":_vm.inputs.identityType,"disabled":!_vm.inputs.issuedCountryUuid || !_vm.size(_vm.identityTypes),"label":`${_vm.$t('modal.identity_card.inputs.document_type.label')}*`,"placeholder":_vm.$t('modal.identity_card.inputs.document_type.placeholder'),"name":"identity_type-selector"},domProps:{"options":_vm.map(
          _vm.identityTypes,
          identityType => ({
            label: _vm.$t(`modal.identity_card.identity_types.${identityType.translationKey}`),
            value: identityType.identityType
          }),
        )},on:{"selectoption":({ detail }) => _vm.inputs.identityType = detail}}),_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.input",value:(_vm.idNumberValidations),expression:"idNumberValidations",modifiers:{"input":true}}],ref:"idNumberInput",staticClass:"d-block",attrs:{"value":_vm.inputs.value,"disabled":!_vm.inputs.identityType,"placeholder":_vm.idNumberPlaceholder,"label":`${_vm.$t('modal.identity_card.inputs.identity_number.label')}*`,"name":"id_number"},on:{"changevalue":_vm.setIdNumberValue}})],1)],1),_c('div',{staticClass:"d-flex justify-content-end",attrs:{"slot":"footer"},slot:"footer"},[_c('ui-button',_vm._b({staticClass:"mr-2",attrs:{"data-test-id":"cancel-button"},on:{"clickbutton":function($event){return _vm.$emit('on:close-modal')}}},'ui-button',_vm.fetchButtonSpecs({ buttonType: _vm.THEME_BUTTON_TYPES.SECONDARY }),false),[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('ui-button',_vm._b({attrs:{"disabled":_vm.isRequestButtonDisabled,"loading":_vm.identityCardFormStatus.LOADING,"data-test-id":"confirm-button"},on:{"clickbutton":_vm.requestUserIdCard}},'ui-button',_vm.fetchButtonSpecs(),false),[_vm._v(" "+_vm._s(_vm.$t('modal.identity_card.inputs.confirm_and_book'))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }