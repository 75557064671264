import { getValue } from '@emobg/web-utils';
import { printableAddress, printableDate } from '@/helpers/booking/locationHelpers';
import { instance as mapMarkerSelector } from '@/services/MapMarkerSelector';

import { useTranslations } from '@/composable/App/Translations';

const { $t } = useTranslations();

export const headerDates = ({ reservation }) => [
  {
    label: $t('new_booking.picker.start'),
    date: printableDate(getValue(reservation, 'start', null)),
    testId: 'start',
  },
  {
    label: $t('new_booking.picker.end'),
    date: printableDate(getValue(reservation, 'end', null)),
    testId: 'end',
  },
];

export const headerAddress = ({ reservation }) => [
  {
    icon: mapMarkerSelector.parkingIcon(),
    address: printableAddress(getValue(reservation, 'location')),
  },
];
