<template>
  <div class="MapViewLayout LayoutPage">
    <div
      class="My-Bookings d-flex"
      :class="{
        'My-Bookings-history': fullWidth
      }"
    >
      <div class="MapViewLayout_left MapViewLayout_left--mybooking">
        <div class="phLayoutSettings">
          <div
            class="GenericTabs"
            :class="`GenericTabs--${currentProfile.isBusinessProfile() ? 'Business' : 'Personal'}`"
          >
            <MuiTabs
              :active="currentTabIndex"
              :tabs="visibleTabs"
              class="TabsContent pt-5"
              @active-tab="goToTab"
            />
          </div>
        </div>
      </div>

      <div
        v-show="!fullWidth"
        class="MapViewLayout_right MapViewLayout_right--mybooking"
      >
        <div
          v-if="display.mapLoaded"
          class="Map_wrapper"
        >
          <div class="phMap_searchWrapper pt-5 px-5">
            <Geocomplete
              ref="geocompleteInput"
              @center-user-position="centerMapInUserCurrentPosition"
              @input-geocomplete-place="onChangeInputGeocomplete"
            />
          </div>
          <GoogleMaps :manager="mapManager" />
        </div>
        <div
          v-else
          class="skeleton w-100 h-100"
        />
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';

import { navigationErrorHandler } from '@emobg/web-utils';
import { COLORS, ICONS, MuiTabs } from '@emobg/vue-base';

import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';
import { ZOOM_LEVEL_TO_VIEW_MARKER } from '@/vue/managers/MapManager';

import { instance as mapMarkerSelector } from '@/services/MapMarkerSelector';

import { BOOKING_EVENTS } from '@Bookings/MyBookings/constants/event-handlers';
import { BOOKING_TYPES } from '@/constants/bookingTypes';

import { userData } from '@/stores/User/UserData/UserDataMapper';
import { getSelectedProfile, isBusinessProfileActive } from '@/stores/User/Profile/ProfileMapper';
import { fetchCSOperator, hasLogBook } from '@/stores/CSOperator/CSOperatorMapper';
import { getMyActiveBookingsAndPrebookings } from '@/stores/Booking/BookingMapper';

import { computeMyBookingsTabs } from '@Bookings/MyBookings/helpers/myBookingsHelpers';

import MyBookingsCore from './mixins/Core';

export default {
  components: {
    MuiTabs,
  },

  mixins: [
    MyBookingsCore,
  ],

  props: {
    bookingTypes: {
      type: Array,
      default: () => [BOOKING_TYPES.carsharing, BOOKING_TYPES.longDistance],
    },
  },

  data() {
    return {
      lastBookingSelected: undefined,
    };
  },
  computed: {
    userData,
    getMyActiveBookingsAndPrebookings,
    hasLogBook,
    isBusinessProfileActive,
    getSelectedProfile,

    fullWidth() {
      const history = this.isRoute(this.routeNames.history);
      const logbook = this.isRoute(this.routeNames.logbook);

      return history || logbook;
    },

    hasOnlyInterventionType() {
      return isEqual(this.bookingTypes, [BOOKING_TYPES.intervention]);
    },

    currentTabIndex() {
      return findIndex(this.visibleTabs, { url: this.$route.name });
    },
  },

  watch: {
    getSelectedProfile(newUuid) {
      if (newUuid) {
        this.onChangeProfile();
      }
    },
  },

  created() {
    this.TABS = computeMyBookingsTabs({
      lastBookingSelected: this.lastBookingSelected,
      mapManager: this.mapManager,
      bookingTypes: this.bookingTypes,
      hasOnlyInterventionType: this.hasOnlyInterventionType,
      showIconPendingLogbook: this.showIconPendingLogbook(),
      hasLogBook: this.hasLogBook,
      isBusinessProfileActive: this.isBusinessProfileActive,
    });
    this.visibleTabs = filter(this.TABS, 'isVisible');

    this.eventHandler.$on(BOOKING_EVENTS.cleanMap, this.cleanMap);
    this.eventHandler.$on(BOOKING_EVENTS.setLastBookingSelected, this.setSelectedBooking);
    this.eventHandler.$on(BOOKING_EVENTS.createBookingMarkers, this.createBookingMarkers);
    this.eventHandler.$on(
      BOOKING_EVENTS.createAndFocusSelectedBookingMarker,
      this.createAndFocusSelectedBookingMarker,
    );

    const dedicatedOperatorUuid = get(this.userData, 'company.dedicated_fleet_cs_operator');
    if (dedicatedOperatorUuid) {
      this.fetchCSOperator(dedicatedOperatorUuid);
    }
  },

  beforeDestroy() {
    this.eventHandler.$off(BOOKING_EVENTS.cleanMap, this.cleanMap);
    this.eventHandler.$off(BOOKING_EVENTS.setLastBookingSelected, this.setSelectedBooking);
    this.eventHandler.$off(BOOKING_EVENTS.createBookingMarkers, this.createBookingMarkers);
    this.eventHandler.$off(
      BOOKING_EVENTS.createAndFocusSelectedBookingMarker,
      this.createAndFocusSelectedBookingMarker,
    );
  },

  methods: {
    fetchCSOperator,

    isOneWayBooking(booking) {
      return get(booking, 'location.original_uuid') !== get(booking, 'destination_location.uuid');
    },

    createBookingMarkers() {
      forEach(this.getMyActiveBookingsAndPrebookings, booking => {
        this.mapManager.addMarker(this.createMarker({
          id: `booking_origin_${booking.uuid}`,
          lat: booking.location.gps_lat,
          long: booking.location.gps_lng,
          icon: mapMarkerSelector.pickupSelected(),
        }));

        if (this.isOneWayBooking(booking)) {
          this.mapManager.addMarker(this.createMarker({
            id: `booking_destination_${booking.uuid}`,
            lat: booking.destination_location.gps_lat,
            long: booking.destination_location.gps_lng,
            icon: mapMarkerSelector.dropOffSelected(),
          }));
        }
      });
    },
    createMarker(config) {
      const google = googleMapsLoader.getInstance();
      const marker = this.mapManager.createMarker(config);

      google.maps.event.addListener(marker, 'click', () => {
        const position = {
          lat: marker.getPosition().lat(),
          lng: marker.getPosition().lng(),
        };

        this.mapManager.setCenterAndZoomMap(position, ZOOM_LEVEL_TO_VIEW_MARKER);
      });

      return marker;
    },
    createAndFocusSelectedBookingMarker(booking) {
      const originId = this.generateOriginMarkerId(booking.uuid);
      const destinationId = this.generateDestinationMarkerId(booking.uuid);

      this.cleanMap();
      this.mapManager.showMarker(originId);

      if (this.isOneWayBooking(booking)) {
        this.mapManager.showMarker(destinationId);
        this.mapManager.drawRoute(originId, destinationId);
      } else {
        this.mapManager.setCenterMap(booking.location.gps_lat, booking.location.gps_lng);
      }
    },
    cleanMap() {
      this.mapManager.hideRoute();

      if (this.lastBookingSelected) {
        const { uuid } = this.lastBookingSelected;

        this.mapManager.hideMarker(this.generateOriginMarkerId(uuid));

        if (this.isOneWayBooking(this.lastBookingSelected)) {
          this.mapManager.hideMarker(this.generateDestinationMarkerId(uuid));
        }
      }
    },
    generateOriginMarkerId(id) {
      return `booking_origin_${id}`;
    },
    generateDestinationMarkerId(id) {
      return `booking_destination_${id}`;
    },
    setSelectedBooking(bookingSelected) {
      this.lastBookingSelected = bookingSelected;
    },
    showIconPendingLogbook() {
      return (this.userData.hasPendingLogbook() && this.currentProfile.isBusinessProfile())
        ? { name: ICONS.alert, color: COLORS.warning } : null;
    },
    goToTab(routeName) {
      this.$router.push({ name: routeName }).catch(navigationErrorHandler);
    },

  },
};

</script>
