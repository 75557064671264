<template>
  <MainViewLayout>
    <template slot="main-content">
      <MyBookingsUsers
        :key="`MyBookingsUser_${hasOnlyInterventionType ? 'intervention' : 'regular' }`"
        :booking-types="bookingTypes"
      />

      <PaymentMethodsHubListener
        @on:payment-authorised="onPaymentAuthorised"
        @on:payment-refused="onPaymentRefused"
      />
    </template>
  </MainViewLayout>
</template>

<script>
import { computed, ref } from 'vue';
import isEqual from 'lodash/isEqual';

import MyBookingsUsers from '@Bookings/MyBookings/MyBookingsUsers';
import MainViewLayout from '@/templates/MainLayout/MainLayout';

import { BOOKING_TYPES } from '@/constants/bookingTypes';

import { checkRedirectFlowOnRouteEnter, checkUrlQueryOnPageLeave, removeCommonStorageOnRouterLeave } from '@Shared/Payments/PaymentMethodsHub/composables/responseUtils';

import PaymentMethodsHubListener from '@Shared/Payments/PaymentMethodsHub/PaymentMethodsHubListener.vue';

export default {
  components: {
    MainViewLayout,
    MyBookingsUsers,

    PaymentMethodsHubListener,
  },

  provide() {
    return {
      paymentSuccess: computed(() => this.paymentSuccessData),
    };
  },

  beforeRouteLeave(_to, _from, next) {
    removeCommonStorageOnRouterLeave();

    next();
  },

  beforeRouteEnter(to, from, next) {
    checkRedirectFlowOnRouteEnter(from, to);

    next();
  },

  props: {
    bookingTypes: {
      type: Array,
      default: () => [BOOKING_TYPES.carsharing, BOOKING_TYPES.longDistance],
    },
  },

  setup() {
    const isPaymentSuccess = ref(false);
    const providePaymentResponse = ref(null);

    const paymentSuccessData = ref({
      isValid: false,
      response: null,
    });

    return {
      isPaymentSuccess,
      providePaymentResponse,
      paymentSuccessData,
    };
  },

  computed: {
    hasOnlyInterventionType() {
      return isEqual(this.bookingTypes, [BOOKING_TYPES.intervention]);
    },
  },

  async created() {
    checkUrlQueryOnPageLeave();
  },

  methods: {
    onPaymentAuthorised(response) {
      this.isPaymentSuccess = true;
      this.providePaymentResponse = response;

      this.paymentSuccessData = {
        isValid: true,
        response,
      };
    },

    onPaymentRefused(response) {
      this.isPaymentSuccess = false;
      this.providePaymentResponse = response;

      this.paymentSuccessData = {
        isValid: false,
        response,
      };
    },
  },
};

</script>
