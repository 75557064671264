import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import replace from 'lodash/replace';
import join from 'lodash/join';
import {
  DELIMITERS,
  getValue, isNumberValue,
  snakeCaseKeys,
} from '@emobg/web-utils';

/**
 * Return object with formatted data to send to Segment
 * @param {string} currencyCode
 * @param {object} price
 * @param {object} booking
 * @param {string} bookingUuid
 * @param {object} vehicle
 * @param {object} location
 * @param {object} paymentMethod
 * @param {object} profile
 * @return {object}
 */
export const composeBookingCreation = ({
  currencyCode, price, booking, bookingUuid, vehicle, location, paymentMethod, profile,
}) => (snakeCaseKeys(omitBy({
  currency: currencyCode,
  value: parseFloat(getValue(price, 'totalRaw', undefined)),
  transactionId: bookingUuid,
  tax: parseFloat(replace(getValue(price, 'vatTotalRaw', undefined), DELIMITERS.comma, DELIMITERS.point)),
  paymentType: getValue(paymentMethod, 'type'),
  coupon: join(getValue(price, 'promotionsCodes'), DELIMITERS.pipe),
  profile: getValue(profile, 'type'),
  items: [
    omitBy({
      index: 0,
      itemId: getValue(vehicle, 'uuid') || getValue(vehicle, 'category'),
      itemListId: getValue(location, 'uuid'),
      itemListName: getValue(location, 'name'),
      itemName: getValue(vehicle, 'model'),
      itemCategory: getValue(vehicle, 'category'),
      itemCategory2: getValue(location, 'city'),
      itemCategory3: getValue(location, 'name'),
      price: parseFloat(getValue(vehicle, 'tariff.hourly_price_raw') / 100),
      quantity: getValue(booking, 'nHours'),
      discount: getValue(price, 'promotionsSubtotal'),
    }, isNil),
  ],
}, value => !isNumberValue(value) && isEmpty(value))));
