import get from 'lodash/get';
import cfg from '@/config';
import collectBrowserInfo from '@Bookings/Summary/adyen/collectBrowserInfo';
import PaymentInvoiceApi from '@Bookings/Summary/adyen/PaymentInvoiceApi';
import ReturnUrl from './ReturnUrl';

const host = cfg.data.phoenixApiUrl;

const bookingPaymentBO = {
  async createPendingBooking(reservationRequest) {
    const pendingBookingResponse = await PaymentInvoiceApi.createBookingPSD2(
      reservationRequest,
    );

    const booking = {
      status: pendingBookingResponse.data.status,
      uuid: pendingBookingResponse.data.uuid,
      invoice: pendingBookingResponse.data.invoice,
      location: pendingBookingResponse.data.location,
      paymentReference: pendingBookingResponse.data.reference,
    };

    return booking;
  },

  payCustomerInitiatedInvoice(invoiceUuid, securityCode, origin) {
    return PaymentInvoiceApi.payCustomerInitiatedInvoice(invoiceUuid, {
      channel: 'web',
      returnUrl: ReturnUrl.getReturnUrl(host, invoiceUuid, window.location.href),
      securityCode,
      browserInfo: collectBrowserInfo(),
      origin,
    });
  },

  getGeneratedToken(details) {
    const fingerprint = get(details, 'threeds2.fingerprint');
    const challenge = get(details, 'threeds2.challengeResult');

    return fingerprint || challenge;
  },
};

export default bookingPaymentBO;
