import result from 'lodash/result';

/**
 * @function collectBrowserInfo
 *
 * @desc Collects available frontend browser info and store it in the properties
 * dictated by the EMVCo spec (3DS_Spec_protocolAndCoreFny_v2-1_Oct2017.pdf)
 *
 * @example const browserInfo = collectBrowserInfo();
 * const userAgent = browserInfo.userAgent;
 *
 * @returns {Object} - browserInfo an object containing the retrieved browser properties
 */
const collectBrowserInfo = () => {
  const screenWidth = result(window, 'screen.width.toString', '');
  const screenHeight = result(window, 'screen.height.toString', '');
  const colorDepth = result(window, 'screen.colorDepth.toString', '');
  const userAgent = result(window, 'navigator.userAgent', '');
  const javaEnabled = result(window, 'navigator.javaEnabled', false);

  let language = '';
  if (window && window.navigator) {
    language = window.navigator.language
      ? window.navigator.language
      : window.navigator.browserLanguage; // Else is for IE <+ 10
  }

  const date = new Date();
  const timeZoneOffset = date.getTimezoneOffset().toString();

  const browserInfo = {
    acceptHeader: 'application/json, text/plain, */*',
    screenWidth,
    screenHeight,
    colorDepth,
    userAgent,
    timeZoneOffset,
    language,
    javaEnabled,
  };

  return browserInfo;
};

export default collectBrowserInfo;
