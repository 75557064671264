export const IDENTITY_TYPE_VALIDATIONS_BY_COUNTRY = {
  ES: {
    identity_card: {
      format: /^[0-9]{8}[A-Z]$/,
      maxLength: 9,
      matchKeys: /[A-Za-z0-9,]/,
      showOnLength: 9,
      messageKey: 'nif',
      example: '12345678A',
      keyToUpperCase: true,
    },
    residence: {
      format: /^[XYZ][0-9]{7}[A-Z]$/,
      maxLength: 9,
      matchKeys: /[A-Za-z0-9,]/,
      showOnLength: 9,
      messageKey: 'nie',
      example: 'A1234567B',
      keyToUpperCase: true,
    },
  },
};

export const IDENTITY_CARD_KEY_ERRORS = {
  invalidCardValue: 'invalid_identity_card_value',
  identityCardRequired: 'identity_card_required',
};
