export const LOGBOOK_TRIP_FIELDS_KEYS = {
  clientAddress: 'clientAddress',
  clientName: 'clientName',
  comment: 'comment',
  overnightReason: 'overnightReason',
  purpose: 'purpose',
  reason: 'reason',
  isOvernight: 'isOvernight',
};

export const LOGBOOK_TRIP_FIELDS = [
  LOGBOOK_TRIP_FIELDS_KEYS.clientAddress,
  LOGBOOK_TRIP_FIELDS_KEYS.clientName,
  LOGBOOK_TRIP_FIELDS_KEYS.comment,
  LOGBOOK_TRIP_FIELDS_KEYS.overnightReason,
  LOGBOOK_TRIP_FIELDS_KEYS.purpose,
  LOGBOOK_TRIP_FIELDS_KEYS.reason,
  LOGBOOK_TRIP_FIELDS_KEYS.isOvernight,
];

export const LOGBOOK_PURPOSE_VALUES = {
  wayIn: 'way_in',
  break: 'break',
  return: 'return',
};
