<template>
  <div>
    <template v-if="shouldDisplayOldFlow">
      <BookingSummary />
    </template>
    <template v-else>
      <BookingSummaryPSD2Refactor />
    </template>
  </div>
</template>

<script>
import Profile from '@/mixins/Profile';

import { storage } from '@Shared/Payments/PaymentMethodsHub/composables/storage';
import { checkRedirectFlowOnRouteEnter, removeCommonStorageOnRouterLeave } from '@Shared/Payments/PaymentMethodsHub/composables/responseUtils';
import { BOOKING_FLOW, BOOKING_PREVIOUS_PAGE } from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';
import BookingSummary from './BookingSummary';
import BookingSummaryPSD2Refactor from './BookingSummaryPSD2Refactor.vue';

export default {
  name: 'BookingSummaryFeatureBridge',

  components: {
    BookingSummary,
    BookingSummaryPSD2Refactor,
  },

  mixins: [
    Profile,
  ],

  beforeRouteEnter(to, from, next) {
    checkRedirectFlowOnRouteEnter(from, to);

    storage.add(BOOKING_PREVIOUS_PAGE, from.path);

    next();
  },

  beforeRouteLeave(_to, from, next) {
    removeCommonStorageOnRouterLeave();

    storage.remove(BOOKING_FLOW.inProcess);

    const { hash } = from.params;

    storage.remove(`bookingHub_${hash}`);
    storage.remove(`booking_${hash}`);

    next();
  },

  computed: {
    shouldDisplayOldFlow() {
      return (
        !this.$featureFlag.flags.psd2CreateBusinessBooking
        && this.currentProfile.isBusinessProfile()
      ) || !this.$featureFlag.flags.boBl116Psd2;
    },
  },
};
</script>
