import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import isBoolean from 'lodash/isBoolean';

import { LOG_TYPE, logger } from '@emobg/web-utils';

import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';
import { distanceBetweenLocations } from '@/helpers/map/distance';
import { BOOKING_TRIP_TYPES } from '@/constants/bookingTripTypes';
import { isLongDistance } from '@/helpers/booking/bookingHelpers';

/**
 * Return object with formatted data to send to Segment
 * @param {object} reservation
 * @param {boolean} created
 * @param {object} bookingSummary
 * @param {string} bookingUuid
 * @param {string} errorMessage
 * @param {string} currencyCode
 * @return {object}
 */
export const composeBookingCreationEvent = ({
  reservation,
  created,
  bookingSummary,
  bookingUuid,
  errorMessage,
  currencyCode,
}) => {
  if (isEmpty(reservation) || !isBoolean(created)) {
    const logErrorMessage = '"reservation" and "created" argument must be provided';
    logger.message(logErrorMessage, LOG_TYPE.error);
    throw new Error(logErrorMessage);
  }
  const google = googleMapsLoader.getInstance();
  const secondLocation = { lat: get(reservation, 'location.lat'), lng: get(reservation, 'location.lng') };

  const userDistance = distanceBetweenLocations({
    google,
    secondLocation,
    firstLocation: get(reservation, 'user.userGeolocation'),
  });
  const userAddressDistance = distanceBetweenLocations({
    google,
    secondLocation,
    firstLocation: get(reservation, 'user.addressGeolocation'),
  });

  return {
    bookingType: get(reservation, 'booking_type'),
    carCategory: get(reservation, 'vehicle.category'),
    carModel: get(reservation, 'vehicle.model'),
    vehicleId: get(reservation, 'vehicle.uuid') || get(reservation, 'vehicle.category'),
    vehicleCategoryTripType: get(reservation, 'vehicleCategory.one_way_allowed')
      ? BOOKING_TRIP_TYPES.oneWay
      : BOOKING_TRIP_TYPES.roundTrip,
    locationTripType: get(reservation, 'location.one_way_allowed')
      ? BOOKING_TRIP_TYPES.oneWay
      : BOOKING_TRIP_TYPES.roundTrip,
    bookingTripType: isEmpty(get(reservation, 'dropOffLocation')) || (get(reservation, 'dropOffLocation.uuid') === get(reservation, 'pickUpLocation.uuid'))
      ? BOOKING_TRIP_TYPES.roundTrip
      : BOOKING_TRIP_TYPES.oneWay,
    value: get(bookingSummary, 'price.total_raw'),
    currency: currencyCode,
    insuranceTye: get(bookingSummary, 'insurance.current_insurance.internal_name'),
    successfulBookingDone: created,
    unsuccessfulBookingDoneReason: errorMessage,
    bookingId: bookingUuid,
    distanceFromTheUser: userDistance,
    distanceFromTheUserAddress: userAddressDistance,
  };
};

/**
 * Return object with formatted data to send "close booking summary" event
 * to Segment
 * @param {object} reservation
 * @param {string} currencyCode
 * @param {number} bookingSummaryTotalPrice
 * @param {array} paymentMethodCollection
 * @return {object}
 */
export const composeCloseBookingSummaryEvent = ({
  reservation, currencyCode, bookingSummaryTotalPrice, paymentMethodCollection,
}) => ({
  value: bookingSummaryTotalPrice,
  currency: currencyCode,
  booking_type: isLongDistance(get(reservation, 'booking_type'))
    ? 'longdistance'
    : 'carsharing',
  payment_method_available: size(paymentMethodCollection),
});
