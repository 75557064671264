import { snakeCaseKeys, toQueryString } from '@emobg/web-utils';
import axios from 'axios';
import cfg from '@/config';
import { ApiHeaders } from '@/vue/api/api.service';

const getConfig = () => ({ headers: ApiHeaders({ auth: true }) });

const host = cfg.data.phoenixApiUrl;
const paymentInvoiceAxios = axios.create({
  baseURL: host,
});

const withHost = endpoint => `${host}/${endpoint}`;

// Needs to be a function because at first not
// everything is ready inside ApiHeaders

const PaymentInvoiceApi = {
  getOriginKey(originDomain, userUuid) {
    const query = toQueryString({
      origin_domain: originDomain,
      user_uuid: userUuid,
    });
    const endpoint = withHost('payments/v2/provider/credentials')
      .concat(query);
    return axios.get(endpoint, getConfig());
  },

  payCustomerInitiatedInvoice(invoiceUuid, {
    channel, returnUrl, securityCode, browserInfo, origin,
  }) {
    const payment = {
      channel,
      returnUrl,
      securityCode,
      browserInfo,
      origin,
    };
    return axios.post(
      withHost(`invoicing/v3/invoices/${invoiceUuid}/pay-customer-initiated`),
      snakeCaseKeys(payment),
      getConfig(),
    );
  },

  get3ds1PaymentResult(invoiceUuid) {
    const url = `/payments/checkout/payment-result/${invoiceUuid}`;

    return paymentInvoiceAxios.get(url, getConfig());
  },
  addUserPaymentMethodPSD2(userUuid, paymentRequest, browserInfo) {
    return axios.post(
      withHost(`payments/v5/users/${userUuid}/payment-methods-web`),
      { ...paymentRequest, browserInfo },
      getConfig(),
    );
  },
  addFrictionlessPayment(userUuid, paymentRequest) {
    return axios.post(
      withHost(`payments/v4/users/${userUuid}/payment-methods`),
      snakeCaseKeys(paymentRequest),
      getConfig(),
    );
  },
  storePaymentMethod(paymentMethodUuid) {
    return axios.get(
      withHost(`payments/store-payment-method/result/user/${paymentMethodUuid}`),
      getConfig(),
    );
  },
  createBookingPSD2(params) {
    return axios.post(
      withHost('carsharing/v4/bookings'),
      params,
      getConfig(),
    );
  },

  getDefaultPaymentMethod(userProfileUuid) {
    const query = toQueryString(snakeCaseKeys({ userProfileUuid }));
    const endpoint = withHost('payments/v4/get-default-payment-method')
      .concat(query);
    return axios.get(endpoint, getConfig());
  },
  authenticate3DS2({
    verificationStep,
    reference,
    generatedToken,
    paymentData,
    userUuid,
    type,
  }) {
    const authenticate3DS2Params = {
      verificationStep,
      reference,
      generatedToken,
      paymentData,
      userUuid,
      type,
    };
    return axios.post(
      withHost('payments/v3/authentication'),
      snakeCaseKeys(authenticate3DS2Params),
      getConfig(),
    );
  },
  // add type
  authenticate3DS1({
    reference,
    MD,
    PaRes,
    paymentData,
    userUuid,
    companyUuid,
    type,
  }) {
    const authenticate3DS1Params = {
      reference,
      session: MD,
      pa_response: PaRes,
      paymentData,
      userUuid,
      company_uuid: companyUuid,
      type,
    };
    return axios.post(
      withHost('payments/v3/confirm-three-d-secure'),
      snakeCaseKeys(authenticate3DS1Params),
      getConfig(),
    );
  },

  resolveBookingPayment(bookingUuid, request) {
    return axios.put(
      withHost(`carsharing/v1/bookings/${bookingUuid}/resolve-payment-pending`),
      snakeCaseKeys(request),
      getConfig(),
    );
  },

  cancelPendingBooking(bookingUuid, request) {
    return axios.put(
      withHost(`carsharing/v1/bookings/${bookingUuid}/cancel-payment-pending`),
      snakeCaseKeys(request),
      getConfig(),
    );
  },
};

export default PaymentInvoiceApi;
