import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import omitBy from 'lodash/omitBy';
import every from 'lodash/every';
import some from 'lodash/some';
import { LOG_TYPE, logger } from '@emobg/web-utils';
import { PURPOSES } from '@/components/Logbook/constants/purposes.const';

import { LOGBOOK_TRIP_FIELDS } from '../constants/logbookTrip';

/**
 * It applies different rules to the trip object received and returns if it is valid
 * @param {object} trip
 * @example <caption>Example usage of validateTrip.</caption>
 * // returns true
 * validateTrip({ isOvernight: false, purpose: 'pick up client', exceededMileage: false })
 * @return {boolean}
 */
export const validateTrip = trip => {
  if (isEmpty(trip)) {
    return false;
  }

  return every([
    !isEmpty(trip.purpose),
    trip.purpose === PURPOSES.wayIn ? !some([trip.reason, trip.clientName, trip.clientAddress], isEmpty) : true,
    trip.isOvernight ? !isEmpty(trip.overnightReason) : true,
    trip.exceededMileage ? !isEmpty(trip.comment) : true,
  ]);
};

/**
 * It sanitized trip and originalTrip and return if those are equal or not
 * @param {object} trip
 * @param {object} originalTrip
 * @example <caption>Example usage of areTripsEqual.</caption>
 * // returns false
 * const trip = { isOvernight: true, purpose: 'pick up client', exceededMileage: false }
 * const tripOrig = { isOvernight: true, purpose: 'pick up client', exceededMileage: true }
 * areTripsEqual(trip, tripOrig)
 *
 * @return {boolean}
 */

export const areTripsEqual = (trip, originalTrip) => {
  if (isEmpty(trip) && isEmpty(originalTrip)) {
    const errorMessage = 'trip and originalTrip arguments can not be both empty values';
    logger.message(errorMessage, LOG_TYPE.error);
    throw new Error(errorMessage);
  }
  const originalTripValues = pick(originalTrip, LOGBOOK_TRIP_FIELDS);
  const tripWithoutState = pick(trip, LOGBOOK_TRIP_FIELDS);

  return isEqual(
    omitBy(originalTripValues, isEmpty),
    omitBy(tripWithoutState, isEmpty),
  );
};
